import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router';
import appRouter from 'routes/routers/app/appRouter';
import 'maplibre-gl/dist/maplibre-gl.css';
import store from 'state/store';
import theme from 'theme';

const App = () => {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={appRouter} />
      </ThemeProvider>
    </ReduxProvider>
  );
};

export default App;
