import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILandscape, IVegetation } from 'domain/types/vegetation';
import undoable, { combineFilters, excludeAction } from 'redux-undo';
import { resetAll } from 'state/resetAction';
import { TUndoRedoOptions } from 'state/slices/types';

export interface LandscapeState {
  landscape: ILandscape;
}

const initialState: LandscapeState = {
  landscape: {
    vegetations: [],
  },
};

export const landscapeSlice = createSlice({
  name: 'landscape',
  initialState,
  reducers: {
    // Generic
    clear: () => initialState,
    clearHistory: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    undo: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    redo: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    // Available
    setVegetations: (state, action: PayloadAction<IVegetation[]>) => {
      state.landscape.vegetations = action.payload;
    },
    setVegetationsWithoutHistory: (state, action: PayloadAction<IVegetation[]>) => {
      state.landscape.vegetations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  // Generic
  clearHistory: clearLandscapeHistory,
  undo: undoLandscape,
  redo: redoLandscape,
  // Available
  setVegetations,
  setVegetationsWithoutHistory,
} = landscapeSlice.actions;

export const landscapeReducer = undoable(landscapeSlice.reducer, {
  filter: combineFilters(
    (action) => action.type.startsWith(landscapeSlice.name + '/'),
    excludeAction([
      // Generic actions
      landscapeSlice.actions.clear.type,
      landscapeSlice.actions.clearHistory.type,
      landscapeSlice.actions.undo.type,
      landscapeSlice.actions.redo.type,
      // Specific actions
      landscapeSlice.actions.setVegetationsWithoutHistory.type,
    ]),
  ),
  clearHistoryType: landscapeSlice.actions.clearHistory.type,
  undoType: landscapeSlice.actions.undo.type,
  redoType: landscapeSlice.actions.redo.type,
});
