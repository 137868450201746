import {
  Avatar,
  Box,
  Button,
  Chip,
  ChipProps,
  CircularProgress,
  Divider,
  Grid2,
  Link,
  Typography,
} from '@mui/material';
import { EKeeValueSubscriptionStatus } from 'api/dtos/kee-value/response';
import LogoKeeValue from 'assets/images/partners/keevalue.svg';
import Logo from 'assets/images/partners/keevalue.svg';
import ModalContentWrapper from 'components/display/ModalContentWrapper';
import IntegrationHead from 'components/features/Integrations/IntegrationHead';
import Modal from 'components/modals/Modal';
import useModals from 'hooks/useModals';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import {
  useDisconnectKeeValueSubscriptionMutation,
  useGetKeeValueSubscriptionAccessQuery,
  useStartNewKeeValueSubscriptionIntegrationMutation,
} from 'state/services/backend/endpoints/keeValue/keeValue';
import ActionConfirmation from 'theme/components/inputs/ActionConfirmation';
import { Link as LinkIcon } from 'theme/icons';
import { EModal } from 'types/enums/Modal';

const styles = {
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    width: '100%',
    color: 'grey.500',
  },
};

interface IKeeValueSubscriptionStatusItem {
  translationKey: string;
  color: ChipProps['color'];
}

type TKeeValueSubscriptionStatusConstants = Record<EKeeValueSubscriptionStatus, IKeeValueSubscriptionStatusItem>;

export const KEE_VALUE_SUBSCRIPTION_STATUS_CONSTANT: TKeeValueSubscriptionStatusConstants = {
  [EKeeValueSubscriptionStatus.NOT_CONNECTED]: {
    translationKey: 'general:empty',
    color: 'default',
  },
  [EKeeValueSubscriptionStatus.VALID]: {
    translationKey: 'general:connected',
    color: 'secondary',
  },
  [EKeeValueSubscriptionStatus.INVALID]: {
    translationKey: 'general:subscriptionExpired',
    color: 'error',
  },
  [EKeeValueSubscriptionStatus.MISSING_MODULE]: {
    translationKey: 'general:moduleMissing',
    color: 'warning',
  },
};

const KeeValueModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { modals, closeModal } = useModals();

  const [verificationActive, setVerificationActive] = useState(false);
  const [keeValueUrl, setKeeValueUrl] = useState<string | null>(null);

  const {
    data: keeValueSubscriptionData,
    isLoading: isGetKeeValueSubscriptionAccessLoading,
    isFetching: isGetKeeValueSubscriptionAccessFetching,
    refetch,
  } = useGetKeeValueSubscriptionAccessQuery(undefined, {
    refetchOnFocus: true,
  });

  const [startNewKeeValueSubscriptionIntegration, { isLoading: isStartNewKeeValueSubscriptionIntegrationLoading }] =
    useStartNewKeeValueSubscriptionIntegrationMutation();

  const [disconnectKeeValueSubscription, { isLoading: isDisconnectKeeValueSubscriptionLoading }] =
    useDisconnectKeeValueSubscriptionMutation();

  const handleCloseModal = () => {
    dispatch(closeModal(EModal.KEE_VALUE_SETTINGS));
  };

  const handleVerifyConnection = () => {
    refetch()
      .unwrap()
      .then((response) => {
        if (response.status === EKeeValueSubscriptionStatus.NOT_CONNECTED) {
          toast.error(t('integrations:partners.keeValue.verificationFailure'));
        } else {
          setVerificationActive(false);
        }
      });
  };

  const handleNewIntegrationButton = async () => {
    const promise = startNewKeeValueSubscriptionIntegration()
      .unwrap()
      .then((response) => {
        setKeeValueUrl(response.url);
        setVerificationActive(true);
      });

    toast.promise(promise, {
      loading: t('general:loading'),
      error: t('general:somethingWentWrong'),
    });
  };

  const handleLogOut = () => {
    const promise = disconnectKeeValueSubscription()
      .unwrap()
      .then(() => {
        handleCloseModal();
        setVerificationActive(false);
      });

    toast.promise(promise, {
      loading: t('general:loading'),
      success: t('integrations:partners.keeValue.disconnectionSuccess'),
      error: t('general:somethingWentWrong'),
    });
  };

  return (
    <>
      <Modal
        open={modals.keeValueSettings.open}
        handleClose={handleCloseModal}
        ContentSx={{ maxWidth: 'none !important' }}
        ContentWrapperSx={{ p: 0 }}
      >
        <ModalContentWrapper maxWidth={450} sx={{ p: 9 }}>
          <Box>
            {isGetKeeValueSubscriptionAccessLoading ? (
              <Box sx={styles.loaderWrapper}>
                <CircularProgress size={28} color='inherit' />
              </Box>
            ) : (
              <>
                <Box>
                  <IntegrationHead
                    title={t('integrations:partners.keeValue.title')}
                    description={t('integrations:partners.keeValue.description')}
                    logo={Logo}
                  />
                  {keeValueSubscriptionData &&
                  keeValueSubscriptionData.status !== EKeeValueSubscriptionStatus.NOT_CONNECTED ? (
                    <>
                      <Divider sx={{ my: 6 }} />
                      <Grid2 container spacing={3}>
                        <Grid2
                          size={{
                            xs: 12,
                            sm: 6,
                          }}
                        >
                          <Typography variant='body2' color='textSecondary'>
                            {t('general:status')}
                          </Typography>
                        </Grid2>
                        <Grid2
                          sx={{ textAlign: 'right' }}
                          size={{
                            xs: 12,
                            sm: 6,
                          }}
                        >
                          <Chip
                            color={KEE_VALUE_SUBSCRIPTION_STATUS_CONSTANT[keeValueSubscriptionData.status].color}
                            label={t(
                              KEE_VALUE_SUBSCRIPTION_STATUS_CONSTANT[keeValueSubscriptionData.status].translationKey,
                            )}
                            size='small'
                          />
                        </Grid2>
                      </Grid2>
                      <Divider sx={{ my: 6 }} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 6 }}>
                        <Button
                          component={Link}
                          variant='outlined'
                          size='medium'
                          href='https://lead-one.keevalue.ch/'
                          target='_blank'
                        >
                          <LinkIcon sx={{ fontSize: 12, mr: 2 }} />
                          {t('integrations:partners.keeValue.title')}
                        </Button>
                        <ActionConfirmation
                          variant='dialog'
                          text={t('integrations:partners.keeValue.disconnectConfirmation')}
                          acceptText={t('general:disconnect')}
                          declineText={t('general:cancel')}
                          onAccept={handleLogOut}
                          AcceptButtonProps={{
                            variant: 'outlined',
                            color: 'error',
                          }}
                        >
                          <Button
                            variant='outlined'
                            color='error'
                            size='medium'
                            disabled={
                              isStartNewKeeValueSubscriptionIntegrationLoading ||
                              isDisconnectKeeValueSubscriptionLoading
                            }
                          >
                            {t('general:disconnect')}
                          </Button>
                        </ActionConfirmation>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box sx={{ display: 'flex', gap: 3, mt: 6 }}>
                        <Button
                          variant='outlined'
                          fullWidth
                          onClick={handleNewIntegrationButton}
                          size='medium'
                          disabled={
                            isStartNewKeeValueSubscriptionIntegrationLoading ||
                            isDisconnectKeeValueSubscriptionLoading ||
                            isGetKeeValueSubscriptionAccessFetching
                          }
                        >
                          {t('general:connectAccount')}
                        </Button>
                        {verificationActive && (
                          <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={handleVerifyConnection}
                            size='medium'
                            disabled={isGetKeeValueSubscriptionAccessFetching}
                          >
                            {t('general:verify')}
                          </Button>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        </ModalContentWrapper>
      </Modal>
      {keeValueUrl && (
        <Modal
          handleClose={() => {
            setKeeValueUrl(null);
          }}
          open
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 6, px: 3 }}>
            <Avatar
              variant='square'
              src={LogoKeeValue}
              role='KeeValue'
              sx={{
                width: 36,
                height: 36,
                transform: 'scale(1.25)',
                border: 1,
                borderColor: 'grey.300',
                borderRadius: 2,
                p: 1,
              }}
            />
            <Typography variant='body2' color='textSecondary'>
              {t('integrations:partners.keeValue.continueInKeeValue')}
              {'...'}
            </Typography>
            <Button
              component={Link}
              variant='contained'
              color='secondary'
              fullWidth
              href={keeValueUrl}
              target='_blank'
              onClick={() => {
                setKeeValueUrl(null);
              }}
            >
              {t('general:continue')}
              <LinkIcon sx={{ fontSize: 14, ml: 1.5 }} />
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default KeeValueModal;
