import Auth0 from 'components/features/Authentication/auth0';

export const prepareHeadersWithAuthToken = async (headers: Headers) => {
  try {
    const token = await Auth0.getAccessTokenSilently();
    if (token) headers.set('authorization', `Bearer ${token}`);
  } catch (e) {
    console.warn('Failed to get access token');
  }

  return headers;
};
