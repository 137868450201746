import { HelpOutline, InfoOutlined } from '@mui/icons-material';
import { Box, SvgIconProps, TooltipProps } from '@mui/material';
import React, { useMemo } from 'react';
import useDevice from 'theme/hooks/useDevice';
import Tooltip from '../Tooltip';
import styles from './styles';

export interface TipperProps extends Omit<TooltipProps, 'title' | 'children' | 'sx' | 'content' | 'PopperProps'> {
  IconProps?: Omit<SvgIconProps, 'sx'>;
  children?: TooltipProps['children'];
  content?: TooltipProps['title'];
  iconVariant?: 'info' | 'help';
  maxWidth?: number;
  maxHeight?: number;
  hasPadding?: boolean;
  sx?: SvgIconProps['sx'];
  PopperSx?: SvgIconProps['sx'];
}

const Tipper = (props: TipperProps) => {
  const {
    IconProps,
    children,
    content,
    iconVariant,
    maxWidth = 400,
    maxHeight = 700,
    hasPadding = true,
    sx,
    PopperSx,
    slotProps,
    ...otherProps
  } = props;

  const { isDesktop } = useDevice();

  const TooltipIcon = useMemo(() => {
    if (iconVariant === 'help') {
      return HelpOutline;
    } else {
      return InfoOutlined;
    }
  }, [iconVariant]);

  return isDesktop && content ? (
    <Tooltip
      placement='top'
      arrow
      slotProps={{
        popper: {
          ...slotProps?.popper,
          sx: [
            styles.popper,
            ...(Array.isArray(PopperSx) ? PopperSx : [PopperSx]),
            !hasPadding && styles.popperNoPadding,
            {
              '& .MuiTooltip-tooltip': {
                maxWidth,
              },
            },
          ],
        },
      }}
      title={
        content ? (
          <Box sx={{ overflow: 'hidden', borderRadius: 2 }}>
            <Box sx={{ overflow: 'auto', maxHeight, px: hasPadding ? 3 : 0, py: hasPadding ? 2 : 0 }}>{content}</Box>
          </Box>
        ) : undefined
      }
      {...otherProps}
    >
      {children || <TooltipIcon {...IconProps} sx={{ ...styles.icon, ...(sx || {}) }} />}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export default Tipper;
