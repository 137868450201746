import { Box, CircularProgress, IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material';
import React from 'react';
import styles from './styles';

interface SearchFieldProps extends Omit<StandardTextFieldProps, 'sx' | 'variant' | 'size'> {
  Icon?: React.ReactNode;
  ButtonIcon?: React.ReactNode;
  onSearch?: () => void;
  isLoading?: boolean;
}

const SearchField = (props: SearchFieldProps) => {
  const { isLoading, Icon, ButtonIcon, onSearch, slotProps, ...otherProps } = props;

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onSearch && event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <TextField
      {...otherProps}
      size='small'
      variant='outlined'
      sx={styles.field}
      classes={undefined}
      onKeyUp={handleKeyUp}
      slotProps={{
        input: {
          ...slotProps?.input,
          startAdornment: Icon ? (
            <InputAdornment position='start'>{Icon}</InputAdornment>
          ) : (
            // @ts-ignore
            slotProps?.input?.startAdornment
          ),
          endAdornment:
            ButtonIcon && onSearch ? (
              <InputAdornment position='end'>
                <IconButton
                  color='primary'
                  sx={{ bgcolor: 'grey.100', p: 1 }}
                  aria-label='directions'
                  onClick={onSearch}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress color='inherit' size={24} /> : ButtonIcon}
                </IconButton>
                {/* @ts-ignore */}
                {slotProps?.input?.endAdornment && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '1px', height: 28, borderRight: 1, borderColor: 'grey.300', ml: 1.5, mr: 3 }} />
                    {/* @ts-ignore */}
                    {slotProps?.input?.endAdornment}
                  </Box>
                )}
              </InputAdornment>
            ) : (
              // @ts-ignore
              slotProps?.input?.endAdornment
            ),
        },
      }}
    />
  );
};

export default SearchField;
