import { booleanPointInPolygon } from '@turf/turf';
import { CreateProjectRequest } from 'api/dtos/project/request';
import { ILngLat } from 'domain/types/location/coordinates';
import { lv95ToWgs, wgsToLV95 } from 'domain/utils/coordinates';
import { Feature, Polygon } from 'geojson';

export const createProjectName = (address: string, cantonAbbr: string) => {
  let name;
  if (!address) {
    name = `Ausgewählter Ort (${cantonAbbr})`;
  } else {
    const addr = address.split(' ');
    name = `${addr[0]} `;
    let hasReachedNumber = false;
    for (let i = 1; i < addr.length; i++) {
      if (!hasReachedNumber) {
        const newPart = addr[i].replace(/,/g, '');
        // @ts-ignore
        if (isNaN(newPart)) name += `${newPart} `;
        else {
          name += `${newPart}`;
          hasReachedNumber = true;
        }
      }
    }
  }
  return name;
};

interface AddressResult {
  attributes: {
    egrid: string;
    label: string;
    dplz4: number;
    dplzname: string;
  };
  geometry: {
    x: number;
    y: number;
  };
}

export const selectAddressFromResult = (params: any, feature: Feature<Polygon>) => {
  const { data = {}, originalArgs } = params;

  const results = data.results as AddressResult[];
  let address = '';
  if (!results || !originalArgs) return { address };

  const { lng, lat } = originalArgs;
  if (!lat || !lng) return { address };
  let d = Infinity;
  const { x: centerX, y: centerY } = wgsToLV95({ lng, lat });

  const filteredResults = results.filter((res) => {
    const { geometry } = res;
    const { lng, lat } = lv95ToWgs(geometry);
    return feature.geometry && booleanPointInPolygon([lng, lat], feature.geometry);
  });

  filteredResults.forEach((res) => {
    const { x, y } = res.geometry;
    const dist = Math.sqrt(Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2));
    if (dist < d) {
      d = dist;
      address = `${res.attributes.label}, ${res.attributes.dplz4} ${res.attributes.dplzname}`;
    }
  });

  if (address === '') {
    results.forEach((res) => {
      const { x, y } = res.geometry;
      const dist = Math.sqrt(Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2));
      if (dist < d) {
        d = dist;
        address = `${res.attributes.label}, ${res.attributes.dplz4} ${res.attributes.dplzname}`;
      }
    });
  }

  return { address };
};

export interface CreateProjectDTOProps {
  address: string;
  area: number;
  cantonAbbr: string;
  egrids: string[];
  isPlotSaveAction?: boolean;
  lngLat: ILngLat;
  plotNumber: string;
  polygon: number[][];
  selectedZoneId: number | null;
}

export const createProjectDTO = (props: CreateProjectDTOProps): CreateProjectRequest => {
  const { address, lngLat, selectedZoneId, egrids, plotNumber, isPlotSaveAction, cantonAbbr, polygon, area } = props;

  const location: CreateProjectRequest['location'] = {
    center: lngLat,
    address,
    plot: {
      plotNumber,
      egrid: egrids[0],
      polygon,
      area,
    },
  };

  const name = createProjectName(address, cantonAbbr);

  return {
    name,
    egrids,
    location,
    selectedZoneId,
    isPlotSaveAction,
  };
};
