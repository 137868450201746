import { Grid2 } from '@mui/material';
import useShopData from 'components/features/ShopModal/hooks/useShopData';
import ShopProductsLoader from 'components/features/ShopModal/views/ShopView/components/ShopProductsLoader/ShopProductsLoader';
import ShopProductsMissing from 'components/features/ShopModal/views/ShopView/components/ShopProductsMissing';
import ProductFeasibilityStudy from 'components/features/ShopModal/views/ShopView/views/ShopProjectView/products/ProductFeasibilityStudy';
import React from 'react';

const ShopProjectFullProductView = () => {
  const { data: shopData, isLoading } = useShopData();

  if (isLoading) return <ShopProductsLoader />;
  if (!shopData) return <ShopProductsMissing />;

  return (
    <Grid2 container spacing={6}>
      <Grid2 size={12}>
        <ProductFeasibilityStudy shopData={shopData} />
      </Grid2>
    </Grid2>
  );
};

export default ShopProjectFullProductView;
