import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { createLogger } from 'redux-logger';
import { StateWithHistory } from 'redux-undo';
import { appListener } from 'state/listeners/appListener';
import setupAppListeners from 'state/listeners/listeners';
import { auth0Api } from 'state/services/auth0/api';
import { appSlice } from 'state/slices/app';
import { buildingsReducer, BuildingsState } from 'state/slices/buildings';
import { cityViewSlice } from 'state/slices/cityView';
import { exploreMapSlice } from 'state/slices/exploreMap';
import { loaderSlice } from 'state/slices/loader';
import { mapSlice } from 'state/slices/map';
import { projectSlice } from 'state/slices/project';
import { terrainReducer, TerrainState } from 'state/slices/terrain';
import { backendApi, geoAdminApi, gisServerApi, lawApi } from './services';
import { toastMiddleware } from 'state/utils/toastMiddleware';
import { compareSlice } from './slices/compare';
import { dashboardSlice } from './slices/dashboard';
import { exploreSlice } from './slices/explore';
import { landscapeReducer, LandscapeState } from './slices/landscape';
import { lawSelectSlice } from './slices/lawSelect';
import { layoutSlice } from './slices/layout';
import { librarySlice } from './slices/library';
import { modalsSlice } from './slices/modals';
import { parkingLotsReducer, ParkingLotsState } from './slices/parkingLots';
import { routerSlice } from './slices/router';
import { shopSlice } from './slices/shop';

const combinedReducer = combineReducers({
  app: appSlice.reducer,
  cityView: cityViewSlice.reducer,
  compare: compareSlice.reducer,
  dashboard: dashboardSlice.reducer,
  explore: exploreSlice.reducer,
  lawSelect: lawSelectSlice.reducer,
  layout: layoutSlice.reducer,
  library: librarySlice.reducer,
  exploreMap: exploreMapSlice.reducer,
  map: mapSlice.reducer,
  modals: modalsSlice.reducer,
  project: projectSlice.reducer,
  router: routerSlice.reducer,
  shop: shopSlice.reducer,
  loader: loaderSlice.reducer,
  buildings: buildingsReducer,
  landscape: landscapeReducer,
  parkingLots: parkingLotsReducer,
  terrain: terrainReducer,
  [backendApi.reducerPath]: backendApi.reducer,
  [geoAdminApi.reducerPath]: geoAdminApi.reducer,
  [lawApi.reducerPath]: lawApi.reducer,
  [gisServerApi.reducerPath]: gisServerApi.reducer,
  [auth0Api.reducerPath]: auth0Api.reducer,
});

const additionalMiddlewares: Middleware[] = [];
if (import.meta.env.REACT_APP_ENABLE_REDUX_LOGGER === 'true') {
  const logger = createLogger({
    level: 'info',
    collapsed: true,
  });

  additionalMiddlewares.push(logger);
}

const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(
        backendApi.middleware,
        geoAdminApi.middleware,
        lawApi.middleware,
        gisServerApi.middleware,
        auth0Api.middleware,
      )
      .concat(additionalMiddlewares)
      .concat(toastMiddleware, appListener.middleware),
});

setupListeners(store.dispatch);
setupAppListeners();

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer> & {
  buildings: StateWithHistory<BuildingsState>;
  landscape: StateWithHistory<LandscapeState>;
  parkingLots: StateWithHistory<ParkingLotsState>;
  terrain: StateWithHistory<TerrainState>;
};

export const useTypedDispatch = useDispatch.withTypes<AppDispatch>();
export const useTypedSelector = useSelector.withTypes<RootState>();
export const useTypedStore = useStore.withTypes<AppStore>();

export default store;
