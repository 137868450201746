import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBuilding, TBuildings } from 'domain/types/building/Building';
import { TBuildingsUsage } from 'domain/types/building/BuildingUsage';
import { IExistingEditableBuilding, TExistingEditableBuildings } from 'domain/types/building/ExistingEditableBuilding';
import { IStaticBuildings } from 'domain/types/building/StaticBuildings';
import undoable, { combineFilters, excludeAction } from 'redux-undo';
import { resetAll } from 'state/resetAction';
import { TUndoRedoOptions } from 'state/slices/types';

const transformBuildings = (buildings: TBuildings | IBuilding[]): TBuildings => {
  if (Array.isArray(buildings)) {
    return buildings.reduce(
      (results, building) => {
        results[building.id] = building;
        return results;
      },
      {} as Record<string, IBuilding>,
    );
  }

  return buildings;
};

const transformExistingEditableBuildings = (
  buildings: TExistingEditableBuildings | IExistingEditableBuilding[],
): TExistingEditableBuildings => {
  if (Array.isArray(buildings)) {
    return buildings.reduce(
      (results, building) => {
        results[building.id] = building;
        return results;
      },
      {} as Record<string, IExistingEditableBuilding>,
    );
  }

  return buildings;
};

export interface BuildingsState {
  buildingsUsage: TBuildingsUsage;
  projectBuildings: TBuildings;
  staticBuildings: IStaticBuildings;
  existingEditableBuildings: TExistingEditableBuildings;
}

const initialState: BuildingsState = {
  buildingsUsage: {},
  projectBuildings: {},
  existingEditableBuildings: {},
  staticBuildings: {
    existingBuildings: [],
    unidentifiedBuildings: [],
  },
};

export const buildingsSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    // Generic
    clear: () => initialState,
    clearHistory: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    undo: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    redo: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    // Available
    setProjectBuildings(state, action: PayloadAction<TBuildings | IBuilding[]>) {
      state.projectBuildings = transformBuildings(action.payload);
    },
    setProjectBuildingsWithoutHistory: (state, action: PayloadAction<TBuildings | IBuilding[]>) => {
      state.projectBuildings = transformBuildings(action.payload);
    },
    setBuildingsUsage(state, action: PayloadAction<TBuildingsUsage>) {
      state.buildingsUsage = action.payload;
    },
    setBuildingsUsageWithoutHistory(state, action: PayloadAction<TBuildingsUsage>) {
      state.buildingsUsage = action.payload;
    },
    setStaticBuildings(state, action: PayloadAction<IStaticBuildings>) {
      state.staticBuildings = action.payload;
    },
    setStaticBuildingsWithoutHistory: (state, action: PayloadAction<IStaticBuildings>) => {
      state.staticBuildings = action.payload;
    },
    setExistingEditableBuildings(
      state,
      action: PayloadAction<TExistingEditableBuildings | IExistingEditableBuilding[]>,
    ) {
      state.existingEditableBuildings = transformExistingEditableBuildings(action.payload);
    },
    setExistingEditableBuildingsWithoutHistory: (
      state,
      action: PayloadAction<TExistingEditableBuildings | IExistingEditableBuilding[]>,
    ) => {
      state.existingEditableBuildings = transformExistingEditableBuildings(action.payload);
    },
    removeAllProjectBuildings(state) {
      state.projectBuildings = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  // Generic
  clearHistory: clearBuildingsHistory,
  undo: undoBuildings,
  redo: redoBuildings,
  // Available
  setProjectBuildings,
  setProjectBuildingsWithoutHistory,
  setBuildingsUsage,
  setBuildingsUsageWithoutHistory,
  setStaticBuildings,
  setStaticBuildingsWithoutHistory,
  setExistingEditableBuildings,
  setExistingEditableBuildingsWithoutHistory,
  removeAllProjectBuildings,
} = buildingsSlice.actions;

export const buildingsReducer = undoable(buildingsSlice.reducer, {
  filter: combineFilters(
    (action) => action.type.startsWith(buildingsSlice.name + '/'),
    excludeAction([
      // Generic
      buildingsSlice.actions.clear.type,
      buildingsSlice.actions.clearHistory.type,
      buildingsSlice.actions.undo.type,
      buildingsSlice.actions.redo.type,
      // Available
      buildingsSlice.actions.setProjectBuildingsWithoutHistory.type,
      buildingsSlice.actions.setStaticBuildingsWithoutHistory.type,
      buildingsSlice.actions.setExistingEditableBuildingsWithoutHistory.type,
    ]),
  ),
  clearHistoryType: buildingsSlice.actions.clearHistory.type,
  undoType: buildingsSlice.actions.undo.type,
  redoType: buildingsSlice.actions.redo.type,
});
