import React from 'react';
import { Navigate, NavigateProps, useLocation } from 'react-router';

const NavigateWithParams = (props: NavigateProps) => {
  const { to, ...otherProps } = props;

  const { search } = useLocation();

  return <Navigate {...otherProps} to={to + search} />;
};

export default NavigateWithParams;
