import { AppState, Auth0Provider } from '@auth0/auth0-react';
import Auth0ClassProvider from 'components/features/Authentication/Auth0ClassProvider';
import { AMENTI_AUTH_RETURN_TO_SESSION_KEY } from 'pages/AuthCallback/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FULL_APP_PATHS } from 'routes/routers/app/appPaths';

interface AuthenticationProviderProps {
  children?: React.ReactNode;
}

const AuthenticationProvider = (props: AuthenticationProviderProps) => {
  const { children } = props;

  const { i18n } = useTranslation();

  const handleRedirectCallback = async (appState: AppState | undefined) => {
    if (appState?.returnTo) {
      sessionStorage.setItem(AMENTI_AUTH_RETURN_TO_SESSION_KEY, appState.returnTo);
    }
  };

  return (
    <Auth0Provider
      domain={import.meta.env.REACT_APP_AUTH0_DOMAIN}
      clientId={import.meta.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${FULL_APP_PATHS.AUTH_CALLBACK.ROOT}`,
        audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
        ui_locales: i18n.language,
      }}
      onRedirectCallback={handleRedirectCallback}
    >
      <Auth0ClassProvider />
      {children}
    </Auth0Provider>
  );
};

export default AuthenticationProvider;
