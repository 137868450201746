import { Box, CircularProgress, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { UserResponse } from 'api/dtos/user/response';
import ModalContentWrapper from 'components/display/ModalContentWrapper';
import Modal from 'components/modals/Modal';
import useModals from 'hooks/useModals';
import useLocationData from 'pages/App/hooks/useLocationData';
import PersonalDetailsForm, {
  IPersonalDetailsFormData,
} from 'pages/Dashboard/Profile/PersonalDetails/PersonalDetailsForm';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { projectIdSelector } from 'state/selectors/router';
import {
  useGetFahrlanderAccessForProjectQuery,
  useGetFahrlanderAccessForSubscriptionQuery,
} from 'state/services/backend/endpoints/fahrlander/fahrlanderAccess';
import { useGetUserQuery } from 'state/services/backend/endpoints/user/user';
import { EModal } from 'types/enums/Modal';
import FahrlanderModalContent from './FahrlanderModalContent';
import FahrlanderRequestToolbar, { EFahrlanderRequestType } from './FahrlanderRequestToolbar';

const styles = {
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    width: '100%',
    color: 'grey.500',
  },
};

interface IRequiredField {
  dataName: keyof UserResponse;
  fieldName: keyof IPersonalDetailsFormData;
}

const requiredFields: IRequiredField[] = [
  {
    dataName: 'firstName',
    fieldName: 'fname',
  },
  {
    dataName: 'lastName',
    fieldName: 'lname',
  },
  {
    dataName: 'phone',
    fieldName: 'phone',
  },
];

const requiredData = requiredFields.reduce((acc, field) => ({ ...acc, [field.fieldName]: true }), {});

const FahrlanderModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { modals, closeModal } = useModals();

  const globalProjectId = useSelector(projectIdSelector);

  const location = useLocationData();
  const isInsideProject = !!location?.egrids.length;

  const [requestType, setRequestType] = useState<EFahrlanderRequestType>(EFahrlanderRequestType.SUBSCRIPTION);
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(undefined);

  const projectId = globalProjectId || selectedProjectId;

  const { data: userData, isLoading: isGetUserLoading } = useGetUserQuery();

  const fahrlanderAccessForProjectResponse = useGetFahrlanderAccessForProjectQuery(
    requestType === EFahrlanderRequestType.PROJECT && projectId ? { projectId } : skipToken,
  );

  const fahrlanderAccessForSubscriptionResponse = useGetFahrlanderAccessForSubscriptionQuery(
    requestType === EFahrlanderRequestType.SUBSCRIPTION ? undefined : skipToken,
  );

  const {
    data: fahrlanderAccess,
    isLoading: isFahrlanderAccessLoading,
    isFetching: isFahrlanderAccessFetching,
  } = requestType === EFahrlanderRequestType.PROJECT
    ? fahrlanderAccessForProjectResponse
    : fahrlanderAccessForSubscriptionResponse;

  const isUserDataFilled = useMemo(
    () => requiredFields.map((field) => !!userData?.[field.dataName]).every(Boolean),
    [userData],
  );

  const handleCloseModal = () => {
    dispatch(closeModal(EModal.FAHRLANDER_SETTINGS));
  };

  return (
    <Modal
      open={modals.fahrlanderSettings.open}
      handleClose={handleCloseModal}
      ContentSx={{ maxWidth: 'none !important' }}
      ContentWrapperSx={{ p: 0 }}
    >
      <ModalContentWrapper maxWidth={550} sx={{ p: 9 }}>
        <Box>
          {isGetUserLoading ? (
            <Box sx={styles.loaderWrapper}>
              <CircularProgress size={28} color='inherit' />
            </Box>
          ) : isUserDataFilled ? (
            <FahrlanderModalContent
              fahrlanderAccess={fahrlanderAccess}
              projectId={projectId}
              isLoading={isFahrlanderAccessLoading || isFahrlanderAccessFetching}
              requestType={requestType}
              isInsideProject={isInsideProject}
            >
              {!isInsideProject && (
                <FahrlanderRequestToolbar
                  requestType={requestType}
                  setRequestType={setRequestType}
                  selectedProjectId={selectedProjectId}
                  setSelectedProjectId={setSelectedProjectId}
                />
              )}
            </FahrlanderModalContent>
          ) : (
            <>
              <Box sx={{ mb: 6 }}>
                <Typography variant='h5' component='h2' fontWeight={500} gutterBottom>
                  {t('integrations:updatePersonalDetails.title')}
                </Typography>
                <Typography variant='body2' color='textSecondary' sx={{ width: '80%' }}>
                  {t('integrations:updatePersonalDetails.description')}
                </Typography>
              </Box>
              <PersonalDetailsForm user={userData} requiredData={requiredData} />
            </>
          )}
        </Box>
      </ModalContentWrapper>
    </Modal>
  );
};

export default FahrlanderModal;
