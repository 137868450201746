import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export enum EGeneralRestrictionType {
  AFFECTING = 'affecting',
  CLEAR = 'clear',
  UNKNOWN = 'unknown',
}

export enum EDatasetTopicCategory {
  RESTRICTIONS = 'restrictions',
  NOISE = 'noise',
}

export enum EDatasetTopicSubcategory {
  SPATIAL_PLANNING = 'spatialPlanning',
  ROADS = 'roads',
  RAILWAYS = 'railways',
  AIRPORTS = 'airports',
  CONTAMINATED_LOCATIONS = 'contaminatedLocations',
  WATER = 'water',
  NOISE = 'noise',
  FOREST = 'forest',
  SUPPLY_AND_DISPOSAL = 'supplyAndDisposal',
}

/**
 * The order of the enum values is important!
 * This order determines how map tooltip attributes are sorted when displayed.
 */
export enum EDatasetTopic {
  // RESTRICTIONS - SPATIAL_PLANNING
  LAND_REGISTER_AND_SERVITUDES = 'land_register_and_servitudes',
  ZONES = 'zones',
  OVERLAYED_RESTRICTIONS = 'overlayed_restrictions',
  ZONES_IN_PLANNING = 'zones_in_planning',
  SPECIAL_PLANS = 'special_plans',
  NATURAL_HAZARDS = 'natural_hazards',
  PROTECTED_HERITAGE_SITES = 'protected_heritage_sites',
  PROTECTED_HISTORIC_ZONES = 'protected_historic_zones',
  PROTECTED_HISTORICAL_BUILDINGS = 'protected_historical_buildings',
  ARCHEOLOGY = 'archeology',
  NATURE_LANDSCAPE_PROTECTION = 'nature_landscape_protection',
  PROTECTED_NATURAL_OBJECTS = 'protected_natural_objects',
  MIXED_RESTRICTIONS = 'mixed_restrictions',

  // RESTRICTIONS - ROADS
  HIGHWAY_RESERVED_ZONES = 'highway_reserved_zones',
  HIGHWAY_BUILDING_LINES = 'highway_building_lines',
  ROAD_DISTANCE_LINES = 'road_distance_lines',

  // RESTRICTIONS - RAILWAYS
  RAILWAYS_RESERVED_ZONES = 'railways_reserved_zones',
  RAILWAYS_CONSTRUCTION_LINES = 'railways_construction_lines',

  // RESTRICTIONS - AIRPORTS
  AIRPORT_RESERVED_ZONES = 'airport_reserved_zones',
  AIRPORT_BUILDING_LINES = 'airport_building_lines',
  AIRPORT_SAFETY_ZONE_PLAN = 'airport_safety_zone_plan',
  AIRPORT_OBSTACLE_LIMITATION = 'airport_obstacle_limitation',

  // RESTRICTIONS - CONTAMINATED_LOCATIONS
  CONTAMINATED_SITES = 'contaminated_sites',

  // RESTRICTIONS - WATER
  GROUND_WATER_PROTECTION = 'ground_water_protection',
  WATER_BORDERS = 'water_borders',
  WATER_DISTANCE_LINES = 'water_distance_lines',

  // RESTRICTIONS - NOISE
  NOISE_SENSITIVITY_LEVEL = 'noise_sensitivity_level',

  // RESTRICTIONS - FOREST
  FOREST_STATIC_BORDERS = 'forest_static_borders',
  FOREST_DISTANCE_LINES = 'forest_distance_lines',

  // RESTRICTIONS - SUPPLY_AND_DISPOSAL
  HEAVY_CURRENT_INSTALLATIONS_RESERVED_ZONES = 'heavy_current_installations_reserved_zones',
  HEAVY_CURRENT_INSTALLATIONS_BUILDING_LINES = 'heavy_current_installations_building_lines',

  // NOISE
  ROAD_NOISE = 'road_noise',
  RAILWAYS_NOISE = 'railways_noise',
  AIRPLANE_NOISE = 'airplane_noise',
}

export interface IDatasetAttribute {
  name: string;
  order: number;
  label?: string;
  type?: string;
  description?: string;
}

export interface IDataset {
  id: number;
  title: string;
  typename: string;
  csw_type: string;
  sourcetype: string;
  store: string;
  resource_type: string;
  abstract: string;
  attribution: string;
  date_source_created: string;
  date_published: string;
  date_updated: string;
  styles: string[];
  default_style: string;
  name: string;
  source_level: ESourceLevel;
  cantons: string[];
  bfs_numbers: string[];
  coverage: EDatasetCoverage;
  data_quality: EDatasetQuality;
  language: string;
  attributes: IDatasetAttribute[];
  geom_name: string;
  features?: IDatasetFeature[];
  isAffecting?: boolean;
  isAvailable?: boolean;
  tiles: string;
  bounds: [number, number, number, number];
  style_json: any[];
}

export enum ESourceLevel {
  CONFEDERATION = 'CONFEDERATION',
  CANTON = 'CANTON',
  MUNICIPALITY = 'MUNICIPALITY',
}

export enum EDatasetQuality {
  GOOD = 'good',
  AVERAGE = 'average',
  BAD = 'bad',
}

export enum EDatasetCoverage {
  FULL = 'full',
  PARTIAL = 'partial',
}

export interface IRestrictionMap {
  topic: EDatasetTopic;
  topic_title_en: string;
  topic_title_de: string;
  topic_title_fr: string;
  topic_title_it: string;
  description_en: string;
  description_de: string;
  description_fr: string;
  description_it: string;
  source_level: ESourceLevel;
  coverage: 'full' | 'partial';
  category: 'restrictions' | 'warnings' | 'noise';
  attribution: string;
  date_created: string;
  last_updated: string;
  datasets: IDataset[];
}

export interface IRestriction {
  map: IRestrictionMap;
  affecting: IDataset[];
  clear: IDataset[];
  unknown: ESourceLevel[];
}

export interface IDatasetFeature {
  id: string;
  properties: {
    w_level?: string | number;
    w_text_en?: string;
    w_text_de?: string;
    w_text_fr?: string;
    w_text_it?: string;
    [key: string]: any;
  };
}

export interface IQualityIssue {
  Icon: OverridableComponent<SvgIconTypeMap<{}>>;
  color: string;
  label: string;
  description: string;
}
