import { Box, Fade, Popover, PopoverProps } from '@mui/material';
import React from 'react';
import ContextMenuItem, { IMenuItem } from './ContextMenuItem';

interface ContextMenuProps extends Omit<PopoverProps, 'open'> {
  maxWidth?: number;
  menuItems: IMenuItem[];
  onOpen?: () => void;
  onClose?: () => void;
}

const ContextMenu = (props: ContextMenuProps) => {
  const { onClick, children, sx, anchorOrigin, transformOrigin, menuItems, maxWidth, onOpen, onClose } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'action-confirmation-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      onOpen?.();

      if (onClick) {
        onClick(event);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  return (
    <Box onClick={handleClick} sx={{ display: 'inline-block', ...sx }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
          ...anchorOrigin,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
          ...transformOrigin,
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 2,
              border: 1,
              borderColor: 'grey.300',
              boxShadow: 4,
            },
          },
        }}
        TransitionComponent={Fade}
      >
        <Box sx={{ maxWidth }}>
          {menuItems.map((menuItem, index) => (
            <ContextMenuItem handleClose={handleClose} options={menuItem} key={`context-menu-item-${index}`} />
          ))}
        </Box>
      </Popover>
      {children}
    </Box>
  );
};

export default ContextMenu;
