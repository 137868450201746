import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { QueryParamAdapter } from 'use-query-params';

interface ReactRouter7AdapterProps {
  children: (adapter: QueryParamAdapter) => React.ReactElement | null;
}

const ReactRouter7Adapter = (props: ReactRouter7AdapterProps): React.ReactElement | null => {
  const { children } = props;

  const navigate = useNavigate();

  return children({
    location: useLocation(),
    push: (location) => navigate({ search: location.search }, { state: location.state }),
    replace: (location) => navigate({ search: location.search }, { replace: true, state: location.state }),
  });
};

export default ReactRouter7Adapter;
