import { useAuth0 } from '@auth0/auth0-react';
import { EAuth0Permission } from 'api/dtos/user/response';
import { useGetUserQuery } from 'state/services/backend/endpoints/user/user';

const useUser = () => {
  const authentication = useAuth0();
  const { isAuthenticated } = authentication;

  const { data, isLoading } = useGetUserQuery(undefined, { skip: !isAuthenticated });

  const isTester = data?.permissions.includes(EAuth0Permission.APP_TESTER) || false;
  const isEmployee = data?.permissions.includes(EAuth0Permission.APP_EMPLOYEE) || false;
  const isAdminLawEditor = data?.permissions.includes(EAuth0Permission.ADMIN_LAW) || false;

  return {
    user: data,
    authentication,
    isTester,
    isEmployee,
    isAdminLawEditor,
    isLoading,
  };
};

export default useUser;
