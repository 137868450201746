import { Box, BoxProps } from '@mui/material';
import React from 'react';

interface ModalContentWrapperProps extends BoxProps {
  maxWidth?: number;
}

const ModalContentWrapper = (props: ModalContentWrapperProps) => {
  const { children, maxWidth = 950, sx = {}, ...otherProps } = props;

  return (
    <Box sx={[{ width: '100vw', maxWidth, overflow: 'hidden' }, ...(Array.isArray(sx) ? sx : [sx])]} {...otherProps}>
      {children}
    </Box>
  );
};

export default ModalContentWrapper;
