import { area } from '@turf/turf';
import { Feature, Polygon } from 'geojson';
import { useDispatch, useSelector } from 'react-redux';
import { mapMutatedPlotSelector, mapSavedMutatedPlotSelector } from 'state/selectors/exploreMap';
import { ICadastre, saveMutatedPlot, setMutatedPlot, setSavedPlot } from 'state/slices/exploreMap';

const useMutablePlot = () => {
  const dispatch = useDispatch();
  const mutatedPlot = useSelector(mapMutatedPlotSelector);
  const savedPlot = useSelector(mapSavedMutatedPlotSelector);

  const updatePlot = (plot: Feature<Polygon, ICadastre>) => {
    dispatch(setMutatedPlot(plot));
  };

  const clearSavedPlot = () => {
    dispatch(setSavedPlot(null));
  };

  const saveEditPlot = () => {
    dispatch(saveMutatedPlot());
  };

  return {
    mutatedPlot,
    savedPlot,
    hasEditedPlot: !!savedPlot,
    savedArea: savedPlot && area(savedPlot),
    clearSavedPlot,
    saveEditPlot,
    updatePlot,
  };
};

export default useMutablePlot;
