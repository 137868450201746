import { EApplicationOverrideTool } from 'types/applicationPath';
import { RootState } from '../store';

// Base selector
export const cityViewSelector = (state: RootState) => state.cityView;

export const cityViewGeneralSelector = (state: RootState) => cityViewSelector(state).general;

export const cityViewSettingsSelector = (state: RootState) => cityViewSelector(state).settings;

export const cityViewCameraTypeSelector = (state: RootState) => cityViewSettingsSelector(state).camera.type;

export const cityViewTerrainWidthSelector = (state: RootState) => cityViewSettingsSelector(state).terrain.width;

export const selectedObjectsSelector = (state: RootState) => state.cityView.selections.selectedObjects;

export const isFacadeSettingsPickerActiveSelector = (state: RootState) =>
  [
    EApplicationOverrideTool.FACADE_WALL_LAYOUT_SETTINGS_PICKER,
    EApplicationOverrideTool.FACADE_MATERIAL_SETTINGS_PICKER,
    EApplicationOverrideTool.FACADE_BALCONY_SETTINGS_PICKER,
  ].includes(cityViewGeneralSelector(state).overrideTool);

export const applicationPathSelector = (state: RootState) => cityViewGeneralSelector(state).applicationPath;

export const addVegetationTypeSelector = (state: RootState) => cityViewSelector(state).landscape.addVegetationType;

export const clippingSettingsSelector = (state: RootState) => cityViewSettingsSelector(state).clipping;

export const showTerrainSelector = (state: RootState) => cityViewSettingsSelector(state).terrain.active;

export const mouseSelector = (state: RootState) => cityViewSelector(state).mouse;

export const isMouseEditingSelector = (state: RootState) => mouseSelector(state).isMouseEditing;

export const isMouseOnGizmoDownSelector = (state: RootState) => mouseSelector(state).isMouseOnGizmoDown;

export const isPersistingChangesSelector = (state: RootState) => mouseSelector(state).isPersistingChanges;

export const isTransformingCameraSelector = (state: RootState) => mouseSelector(state).isTransformingCamera;

export const isMouseDownSelector = (state: RootState) => mouseSelector(state).isMouseDown;

export const snappingSelector = (state: RootState) => cityViewSettingsSelector(state).snapping;

export const isSnappingActiveSelector = (state: RootState) => cityViewSettingsSelector(state).snapping.active;

export const disableClickInteractionSelector = (state: RootState) =>
  cityViewGeneralSelector(state).disableClickInteraction;

export const solarExposureSettingsSelector = (state: RootState) => cityViewSettingsSelector(state).solarExposure;

export const overrideToolSelector = (state: RootState) => cityViewGeneralSelector(state).overrideTool;

export const terrainSettingsSelector = (state: RootState) => cityViewSettingsSelector(state).terrain;

export const offsetTerrainSelector = (state: RootState) => cityViewSettingsSelector(state).offsetTerrain;

export const isDesktopSelector = (state: RootState) => cityViewGeneralSelector(state).isDesktop;
