import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'state/resetAction';
import { dashboardApiSlice } from 'state/services/backend/endpoints/project/dashboard';
import { IDashboardProject } from 'types/project/project';

export enum EProjectsView {
  USER = 'user',
  SHARED = 'shared',
}

const initialState: DashboardState = {
  currentPage: 1,
  currentProject: undefined,
  perPage: 10,
  plotFilter: {
    plotNumber: '',
    municipality: '',
  },
  search: '',
  isDuplicating: false,
  isDeleting: false,
  selectedProjects: [],
  renameOpen: false,
  projectsView: EProjectsView.USER,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clear: () => initialState,
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setCurrentProjectId(state, action: PayloadAction<string | undefined>) {
      state.currentProjectId = action.payload;
    },
    clearCurrentProject(state) {
      state.currentProject = undefined;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.currentPage = 1;
    },
    clearSearch(state) {
      state.search = '';
      state.currentPage = 1;
    },
    setPlotFilter(state, action: PayloadAction<IPlotFilter>) {
      state.currentPage = 1;
      state.plotFilter = {
        plotNumber: action.payload.plotNumber,
        municipality: action.payload.municipality,
      };
    },
    addSelectedProject(state, action: PayloadAction<string>) {
      state.selectedProjects = [...state.selectedProjects, action.payload];
    },
    removeSelectedProject(state, action: PayloadAction<string>) {
      state.selectedProjects = state.selectedProjects.filter((projectId) => projectId !== action.payload);
    },
    setSelectedProjects(state, action: PayloadAction<string[]>) {
      state.selectedProjects = action.payload;
    },
    clearSelectedProjects(state) {
      state.selectedProjects = [];
    },
    clearPlotFilter(state) {
      state.plotFilter = {
        plotNumber: '',
        municipality: '',
      };
      state.currentPage = 1;
    },
    openRename(state) {
      state.renameOpen = true;
    },
    closeRename(state) {
      state.renameOpen = false;
    },
    setProjectsView(state, action: PayloadAction<EProjectsView>) {
      state.projectsView = action.payload;
    },
    toggleProjectsView(state) {
      state.projectsView = state.projectsView === EProjectsView.USER ? EProjectsView.SHARED : EProjectsView.USER;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
    builder.addMatcher(
      isAnyOf(
        dashboardApiSlice.endpoints.deleteProjects.matchPending,
        dashboardApiSlice.endpoints.deleteProject.matchPending,
      ),
      (state) => {
        state.isDeleting = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        dashboardApiSlice.endpoints.deleteProjects.matchFulfilled,
        dashboardApiSlice.endpoints.deleteProject.matchFulfilled,
      ),
      (state) => {
        state.selectedProjects = [];
        state.isDeleting = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        dashboardApiSlice.endpoints.deleteProjects.matchRejected,
        dashboardApiSlice.endpoints.deleteProjects.matchRejected,
      ),
      (state) => {
        state.isDeleting = false;
      },
    );
    builder.addMatcher(dashboardApiSlice.endpoints.duplicateProject.matchPending, (state) => {
      state.isDuplicating = true;
    });
    builder.addMatcher(dashboardApiSlice.endpoints.duplicateProject.matchFulfilled, (state) => {
      state.isDuplicating = false;
    });
    builder.addMatcher(dashboardApiSlice.endpoints.duplicateProject.matchRejected, (state) => {
      state.isDuplicating = false;
    });
  },
});

interface IPlotFilter {
  plotNumber: string;
  municipality: string;
}

export type DashboardState = {
  currentPage: number;
  currentProjectId?: string;
  currentProject?: IDashboardProject;
  perPage: number;
  plotFilter: IPlotFilter;
  search: string;
  isDuplicating: boolean;
  isDeleting: boolean;
  selectedProjects: string[];
  renameOpen: boolean;
  projectsView: EProjectsView;
};
