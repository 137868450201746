import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface SiteHelmetProps {
  pageTitle?: string;
}

interface SiteHelmetProps {
  pageTitle?: string;
}

const SiteHelmet = (props: SiteHelmetProps) => {
  const { pageTitle } = props;

  const title = pageTitle ? `${pageTitle} - Amenti` : 'Amenti';

  const { i18n } = useTranslation();

  return (
    <Helmet
      htmlAttributes={{
        lang: i18n.language,
        className: 'notranslate',
        translate: 'no',
      }}
    >
      <title lang={i18n.language}>{title}</title>
    </Helmet>
  );
};

export default SiteHelmet;
