import { ILngLat } from 'domain/types/location/coordinates';

export enum ERequestSomethingType {
  DEMO = 'demo',
  SERVICE = 'service',
  SUBSCRIPTION = 'subscription',
  CONTACT = 'contact',
}

export interface RequestSomethingRequest {
  fullname: string | null;
  phone: string | null;
  company: string | null;
  email: string;
  newsletterAgree: boolean | null;
  type: ERequestSomethingType;
}

export interface RequestHelpRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
}

export interface RequestSubscriptionRequest {
  comment: string;
}

export enum ELawChangeRequestLawType {
  CANTON = 'canton',
  MUNICIPALITY = 'municipality',
}

export enum ELawChangeRequestType {
  ADD = 'add',
  CHANGE = 'change',
}

export interface RequestLawRequest {
  message: string;
  requestType: ELawChangeRequestType;
  lawType: ELawChangeRequestLawType;
  location: ILngLat;
}

export interface RequestGisRequest {
  message: string;
  location: ILngLat;
}
