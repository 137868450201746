import { alpha, Box, Grid2, Theme, Typography } from '@mui/material';
import { IDataStatusWithData, TDataStatus } from 'api/dtos/general/data/dto/response';
import { EDataDateSpecificity, EDataQualityIndex } from 'api/dtos/general/data/enums';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Circle from 'theme/components/display/Circle';
import Tipper from 'theme/components/display/Tipper';
import Tooltip, { TooltipProps } from 'theme/components/display/Tooltip/Tooltip';
import useDevice from 'theme/hooks/useDevice';
import { WarningCircle } from 'theme/icons';

const styles = {
  wrapper: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      left: -8,
      top: 0,
      height: '100%',
      width: 'calc(100% + 16px)',
      borderRadius: 1,
    },
    '&:hover': {
      '&:before': {
        bgcolor: (theme: Theme) => ({
          sm: alpha(theme.palette.grey[300], 0.25),
        }),
      },
    },
  },
  grid: {
    position: 'relative',
    zIndex: 1,
  },
  typeWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  qualityIndexWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
} as const;

const DATA_QUALITY_COLOR: Record<EDataQualityIndex, string> = {
  [EDataQualityIndex.MISSING]: 'red.500',
  [EDataQualityIndex.LOW]: 'orange.700',
  [EDataQualityIndex.MEDIUM]: 'orange.300',
  [EDataQualityIndex.HIGH]: 'green.500',
};

interface QualityIndexProps {
  qualityIndex: EDataQualityIndex;
  tooltipPlacement?: TooltipProps['placement'];
}

export const QualityIndex = (props: QualityIndexProps) => {
  const { qualityIndex, tooltipPlacement } = props;

  const { t } = useTranslation();

  const color = DATA_QUALITY_COLOR[qualityIndex];

  return (
    <Tooltip
      title={`${t(`project:dataOverview.qualityIndex.${qualityIndex}.label`)} - ${t(`project:dataOverview.qualityIndex.${qualityIndex}.description`)}`}
      color={color}
      arrow
      placement={tooltipPlacement}
    >
      <Box sx={{ display: 'flex', gap: 0.75 }}>
        {qualityIndex === undefined ? (
          <WarningCircle
            sx={{
              fontSize: 12,
              color,
            }}
          />
        ) : (
          <Circle color={color} size={12} />
        )}
      </Box>
    </Tooltip>
  );
};

export const formatDate = (
  date: string | undefined,
  dateSpecificity?: EDataDateSpecificity | undefined,
  shorten?: boolean,
) => {
  if (date === undefined) return '—';

  let dateFormat = shorten ? 'DD.MM.YYYY' : 'DD. MMM YYYY';

  if (dateSpecificity === EDataDateSpecificity.MONTH) {
    dateFormat = shorten ? 'MM.YYYY' : 'MMM YYYY';
  }

  if (dateSpecificity === EDataDateSpecificity.YEAR) {
    dateFormat = 'YYYY';
  }

  return moment(date).format(dateFormat);
};

const isDataStatusWithData = (dataStatus: TDataStatus): dataStatus is IDataStatusWithData => dataStatus.hasData;

interface DataOverviewRowProps {
  dataStatus: TDataStatus;
}

const DataOverviewRow = (props: DataOverviewRowProps) => {
  const { dataStatus } = props;

  const { t } = useTranslation();

  const hasData = isDataStatusWithData(dataStatus);

  const { isSm } = useDevice();

  return (
    <Box sx={styles.wrapper}>
      <Grid2 container spacing={3} sx={styles.grid}>
        <Grid2 size={4.5}>
          <Box sx={styles.typeWrapper}>
            <Typography
              variant='body2'
              noWrap
              sx={{ minWidth: 0 }}
              title={t(`project:dataOverview.dataTypes.${dataStatus.type}.label`)}
            >
              {t(`project:dataOverview.dataTypes.${dataStatus.type}.label`)}
            </Typography>
            {hasData && dataStatus.note && <Tipper content={dataStatus.note} sx={{ ml: 1 }} />}
          </Box>
        </Grid2>
        <Grid2 size={2}>
          <Typography variant='body2' noWrap>
            {hasData && dataStatus.source ? dataStatus.source : '—'}
          </Typography>
        </Grid2>
        <Grid2 size={2}>
          <Typography variant='body2' noWrap textAlign='right'>
            {hasData && dataStatus.sourceUpdateDate
              ? formatDate(dataStatus.sourceUpdateDate, dataStatus.amentiDateSpecificity, !isSm)
              : '—'}
          </Typography>
        </Grid2>
        <Grid2 size={2}>
          <Typography variant='body2' noWrap textAlign='right'>
            {hasData && dataStatus.amentiUpdateDate
              ? formatDate(dataStatus.amentiUpdateDate, dataStatus.amentiDateSpecificity, !isSm)
              : '—'}
          </Typography>
        </Grid2>
        <Grid2 size={1.5} sx={styles.qualityIndexWrapper}>
          <QualityIndex qualityIndex={hasData ? dataStatus.qualityIndex : EDataQualityIndex.MISSING} />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default DataOverviewRow;
