import { XYZ } from 'domain/types/location/coordinates';

export enum ETerrainWidth {
  SMALL = 200,
  LARGE = 500,
}

export type TTerrain = XYZ[][];

export interface ITerrainProperties {
  id: string;
  highestPoint: XYZ;
  lowestPoint: XYZ;
  midpointElevation: number;
  terrainWidth: number;
}

export interface ITerrainWithProperties {
  terrain: TTerrain;
  properties: ITerrainProperties;
}

export interface IModifiedTerrain {
  edgePoints: XYZ[];
  innerPoints: XYZ[];
}

export interface IOriginalTerrain {
  id: string;
  terrainData: TTerrain;
  midpointElevation: number;
}

export interface ITerrain {
  modifiedTerrain: IModifiedTerrain;
  visibleBuildings: string[];
  originalTerrain?: IOriginalTerrain;
}
