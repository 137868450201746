import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModifiedTerrain, IOriginalTerrain, ITerrain } from 'domain/types/terrain';
import undoable, { combineFilters, excludeAction } from 'redux-undo';
import { resetAll } from 'state/resetAction';
import { TUndoRedoOptions } from 'state/slices/types';

export type TerrainState = ITerrain;

const initialState: TerrainState = {
  modifiedTerrain: {
    edgePoints: [],
    innerPoints: [],
  },
  visibleBuildings: [],
  originalTerrain: undefined,
};

export const terrainSlice = createSlice({
  name: 'terrain',
  initialState,
  reducers: {
    // Generic
    clear: () => initialState,
    clearHistory: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    undo: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    redo: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    // Internal
    _setOriginalTerrain: (state, action: PayloadAction<IOriginalTerrain | undefined>) => {
      state.originalTerrain = action.payload;
    },
    // Available
    setModifiedTerrain: (state, action: PayloadAction<IModifiedTerrain>) => {
      state.modifiedTerrain = action.payload;
    },
    setModifiedTerrainWithoutHistory: (state, action: PayloadAction<IModifiedTerrain>) => {
      state.modifiedTerrain = action.payload;
    },
    toggleBuildingVisibility: (state, action: PayloadAction<string>) => {
      const index = state.visibleBuildings.indexOf(action.payload);

      if (index === -1) {
        state.visibleBuildings.push(action.payload);
      } else {
        state.visibleBuildings.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  // Generic
  clearHistory: clearTerrainHistory,
  undo: undoTerrain,
  redo: redoTerrain,
  // Internal
  _setOriginalTerrain,
  // Available
  setModifiedTerrain,
  setModifiedTerrainWithoutHistory,
  toggleBuildingVisibility,
} = terrainSlice.actions;

export const terrainReducer = undoable(terrainSlice.reducer, {
  filter: combineFilters(
    (action) => action.type.startsWith(terrainSlice.name + '/'),
    excludeAction([
      // Generic
      terrainSlice.actions.clear.type,
      terrainSlice.actions.clearHistory.type,
      terrainSlice.actions.undo.type,
      terrainSlice.actions.redo.type,
      // Internal
      terrainSlice.actions._setOriginalTerrain.type,
    ]),
  ),
  clearHistoryType: terrainSlice.actions.clearHistory.type,
  undoType: terrainSlice.actions.undo.type,
  redoType: terrainSlice.actions.redo.type,
});
