import useLayoutView from 'hooks/useLayout/useLayoutView';
import React from 'react';
import { Navigate, useLocation } from 'react-router';

interface NavigateLastProps {
  path: string[];
  initialView: string | null;
}

const NavigateLast = (props: NavigateLastProps) => {
  const { path, initialView, ...otherProps } = props;

  const { search } = useLocation();

  const { currentView } = useLayoutView({
    path,
    initialView,
  });

  return <Navigate {...otherProps} to={currentView + search} replace />;
};

export default NavigateLast;
