import { Box, Grid2, LinearProgress, Tab, Tabs, Theme, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import DataOverviewHeader from 'components/modals/DataOverviewModal/components/DataOverviewHeader';
import DataOverviewRow, {
  formatDate,
  QualityIndex,
} from 'components/modals/DataOverviewModal/components/DataOverviewRow';
import useLayoutView from 'hooks/useLayout/useLayoutView';
import useLocationData from 'pages/App/hooks/useLocationData';
import camelCase from 'lodash/camelCase';
import ModalHeader from 'pages/App/Project/views/Modals/ModalHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { projectIdSelector } from 'state/selectors/router';
import { useGetDataOverviewQuery } from 'state/services/backend/endpoints/general/dataOverview';
import { useGetProjectQueryState } from 'state/services/backend/endpoints/project/project';
import { selectedZoneIdSelector } from 'state/slices/lawSelect';
import { FileRows } from 'theme/icons';
import { GetDataOverviewResponse, IRestrictionDataStatusWithData } from '../../../api/dtos/general/data/dto/response';
import { EDatasetTopic } from '../../features/Plot/PlotRestrictions/types';

const styles = {
  tabs: {
    minHeight: 28,
    height: 28,
  },
  tab: {
    px: 0,
    pb: 2,
    pt: 0,
    mr: 4.5,
    minWidth: 0,
    minHeight: 32,
    fontSize: 13,
    fontWeight: 400,
    textTransform: 'none',
    overflow: 'initial',
    transition: (theme: Theme) => theme.transitions.create('all'),
  },
};

enum EDataOverviewCategory {
  FUNDAMENTALS = 'fundamentals',
  LAW = 'law',
  NOISE = 'noise',
  RESTRICTIONS = 'restrictions',
}

const DataOverviewModalContent = () => {
  const { t } = useTranslation();

  const locationData = useLocationData();

  const reduxSelectedZoneId = useSelector(selectedZoneIdSelector);

  const projectId = useSelector(projectIdSelector);
  const { data: projectData } = useGetProjectQueryState(projectId ? { projectId } : skipToken);

  const selectedZoneId = projectId && projectData ? projectData.generalSettings.selectedZone : reduxSelectedZoneId;

  const { currentView, setView } = useLayoutView<EDataOverviewCategory>({
    initialView: EDataOverviewCategory.FUNDAMENTALS,
    path: ['modals', 'dataOverview'],
  });

  const { data, isLoading } = useGetDataOverviewQuery({
    lng: locationData?.centerLng ?? 0,
    lat: locationData?.centerLat ?? 0,
    egrids: locationData?.egrids ?? [],
    selectedZone: selectedZoneId ?? undefined,
  });
  const { general, restrictions } = (data ?? {}) as GetDataOverviewResponse;

  return (
    <Box>
      <ModalHeader title={t('project:dataOverview.title')} Icon={FileRows} />
      <Box
        sx={{
          px: { xs: 4, lg: 6, xl: 8 },
          pb: { xs: 3, lg: 5, xl: 7 },
          pt: 0,
          width: '100vw',
          minWidth: 0,
          maxWidth: 1080,
        }}
      >
        {isLoading ? (
          <Box sx={{ py: 3 }}>
            <LinearProgress color='primary' />
          </Box>
        ) : data ? (
          <>
            <Tabs value={currentView} onChange={(_, view) => setView(view)} indicatorColor='secondary' sx={styles.tabs}>
              {[
                EDataOverviewCategory.FUNDAMENTALS,
                EDataOverviewCategory.RESTRICTIONS,
                EDataOverviewCategory.LAW,
                EDataOverviewCategory.NOISE,
              ].map((page) => (
                <Tab label={t(`project:dataOverview.topics.${page}.title`)} value={page} sx={styles.tab} key={page} />
              ))}
            </Tabs>
            <Box sx={{ mt: 6 }}>
              <Grid2 container spacing={3}>
                {currentView === EDataOverviewCategory.FUNDAMENTALS && (
                  <>
                    <Grid2 size={12}>
                      <DataOverviewHeader />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.terrain} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.trees} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.satelliteImages} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.existingBuildings3D} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.municipalityGeometry} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.cadastre} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.existingBuildingsRegister} />
                    </Grid2>
                  </>
                )}
                {currentView === EDataOverviewCategory.RESTRICTIONS && restrictions && (
                  <RestrictionsOverviewSection restrictions={restrictions} />
                )}
                {currentView === EDataOverviewCategory.LAW && (
                  <>
                    <Grid2 size={12}>
                      <DataOverviewHeader />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.federalLaw} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.federalParameters} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.cantonalLaw} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.cantonalParameters} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.municipalityLaw} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.municipalityParameters} />
                    </Grid2>
                  </>
                )}
                {currentView === EDataOverviewCategory.NOISE && (
                  <>
                    <Grid2 size={12}>
                      <DataOverviewHeader />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.noiseSensitivityLevel} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.carNoiseOverview} />
                    </Grid2>
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.trainNoiseOverview} />
                    </Grid2>{' '}
                    <Grid2 size={12}>
                      <DataOverviewRow dataStatus={general.aerialNoise} />
                    </Grid2>
                  </>
                )}
              </Grid2>
            </Box>
          </>
        ) : (
          <Typography variant='body2'>{t('general:noDataToDisplay')}</Typography>
        )}
      </Box>
    </Box>
  );
};

interface RestrictionsOverviewSectionProps {
  restrictions?: Record<EDatasetTopic, { datasets: IRestrictionDataStatusWithData[] }>;
}

const RestrictionsOverviewSection = (props: RestrictionsOverviewSectionProps) => {
  const { restrictions } = props;
  const { t } = useTranslation();
  if (!restrictions) return;
  return (
    <Grid2 container spacing={3} size={12}>
      <Grid2 size={3}>
        <Typography variant='body2' fontWeight={500} noWrap>
          {t('project:dataOverview.header.type')}
        </Typography>
      </Grid2>
      <Grid2 size={3.5}>
        <Typography variant='body2' fontWeight={500} noWrap>
          {t('project:dataOverview.header.source')}
        </Typography>
      </Grid2>
      <Grid2 size={2}>
        <Typography variant='body2' textAlign='right' fontWeight={500} noWrap>
          {t('project:dataOverview.header.sourceDate')}
        </Typography>
      </Grid2>
      <Grid2 size={2}>
        <Typography variant='body2' textAlign='right' fontWeight={500} noWrap>
          {t('project:dataOverview.header.updateDate')}
        </Typography>
      </Grid2>
      <Grid2 size={1.5}>
        <Typography variant='body2' textAlign='right' fontWeight={500} noWrap>
          {t('project:dataOverview.header.qualityIndex')}
        </Typography>
      </Grid2>

      {Object.entries(restrictions)
        .filter(([_, restriction]) => restriction.datasets.length > 0)
        .map(([key, restriction]) => {
          return (
            <Grid2 container size={12}>
              <Grid2 size={3}>
                <Typography>{t(`project:datasets.topics.${camelCase(key)}`)}</Typography>
              </Grid2>
              <Grid2 size={9}>
                {restriction.datasets.map((dataset) => {
                  return (
                    <Grid2 container size={12} spacing={3} py={1}>
                      <Grid2 size={4}>
                        <Typography variant='body2' noWrap>
                          {dataset.attribution}
                        </Typography>
                      </Grid2>
                      <Grid2 size={3}>
                        <Typography variant='body2' noWrap textAlign='right'>
                          {formatDate(dataset.sourceUpdateDate)}
                        </Typography>
                      </Grid2>
                      <Grid2 size={3}>
                        <Typography variant='body2' noWrap textAlign='right'>
                          {formatDate(dataset.amentiUpdateDate)}
                        </Typography>
                      </Grid2>
                      <Grid2
                        size={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <QualityIndex qualityIndex={dataset.qualityIndex} />
                      </Grid2>
                    </Grid2>
                  );
                })}
              </Grid2>
            </Grid2>
          );
        })}
    </Grid2>
  );
};

export default DataOverviewModalContent;
