import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutViewKeySelector } from 'state/selectors/layout';
import { setLayoutView } from 'state/slices/layout';
import { RootState } from 'state/store';
import { getLayoutPathString } from './util';

export interface UseLayoutViewProps<T> {
  path: string[];
  initialView: T;
  viewKey?: string;
}

const useLayoutView = <T>(props: UseLayoutViewProps<T>) => {
  const { path, initialView, viewKey } = props;

  const dispatch = useDispatch();

  const key = useMemo(() => getLayoutPathString(path), [path]);

  const memoizedLayoutViewKeySelector = useMemo(() => layoutViewKeySelector, []);
  const layoutViewState: T = useSelector((state: RootState) => memoizedLayoutViewKeySelector(state, key));

  const setView = useCallback(
    (view: T) =>
      dispatch(
        setLayoutView({
          key,
          view,
        }),
      ),
    [dispatch, key],
  );

  const currentView = layoutViewState === undefined ? initialView : layoutViewState;

  return useMemo(
    () => ({
      currentView,
      setView,
      isActiveView: currentView === viewKey,
    }),
    [currentView, setView, viewKey],
  );
};

export default useLayoutView;
