import { ProjectIdRequest } from 'api/dtos/general/request';
import {
  ChangeProjectSettingsRequest,
  CreateProjectRequest,
  GetProjectUtilizationByDtoRequest,
  ISetProjectGeneralSettingsRequest,
  SetConfidenceLevelRequest,
  SetLawZoneRequest,
} from 'api/dtos/project/request';
import {
  CreateProjectResponse,
  GetProjectResponse,
  IProjectSia416Areas,
  IProjectVolumes,
  RentIncomeResponse,
  SaleIncomeResponse,
} from 'api/dtos/project/response';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { BKPCostsResponse, EBKPCostsResponseDTO } from 'types/building/queries';
import { IProjectUtilization } from 'types/project/utilization';
import { RequestMethod } from 'types/request';

export const projectApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    createProject: builder.mutation<CreateProjectResponse, CreateProjectRequest>({
      query: (body) => ({
        url: `/api/project`,
        method: RequestMethod.POST,
        body,
      }),
      invalidatesTags: [EBackendTag.PROJECT, EBackendTag.CREDITS],
    }),
    getProject: builder.query<GetProjectResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}`,
        method: RequestMethod.GET,
      }),
      // transformResponse: (response: GetProjectResponse) => {
      //   return {
      //     ...response,
      //     location: {
      //       ...response.location,
      //       lngLat: {
      //         lng: response.location.centerLng,
      //         lat: response.location.centerLat,
      //       },
      //     },
      //   };
      // },
      providesTags: [EBackendTag.PROJECT],
    }),
    changeProjectSettings: builder.mutation<any, ChangeProjectSettingsRequest>({
      query: ({ projectId, name, description }) => ({
        url: `/api/project/${projectId}/change-settings`,
        method: RequestMethod.PUT,
        body: { name, description },
      }),
      invalidatesTags: [EBackendTag.PROJECT],
    }),
    getProjectBkpCost: builder.query<BKPCostsResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/costs/bkp`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_COST, EBackendTag.INVALID_AFTER_SAVE],
      // A lot of queries invalidate the project costs tag, so we use this tag to invalidate KeeValue calculation settings as well
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        await queryFulfilled;

        dispatch(backendApi.util.invalidateTags([EBackendTag.KEE_VALUE_CALCULATION_SETTINGS]));
      },
    }),
    getProjectEbkpCost: builder.query<EBKPCostsResponseDTO, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/costs/ebkp`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_COST, EBackendTag.INVALID_AFTER_SAVE],
    }),
    getProjectRentIncome: builder.query<RentIncomeResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/incomes/rent`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_INCOME, EBackendTag.INVALID_AFTER_SAVE],
    }),
    getProjectSaleIncome: builder.query<SaleIncomeResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/incomes/sale`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_INCOME, EBackendTag.INVALID_AFTER_SAVE],
    }),
    getProjectSiaAreas: builder.query<IProjectSia416Areas, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project-areas/${projectId}/sia416`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_AREAS, EBackendTag.INVALID_AFTER_SAVE],
    }),
    getProjectVolumes: builder.query<IProjectVolumes, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/volumes`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_VOLUMES, EBackendTag.INVALID_AFTER_SAVE],
    }),
    getProjectUtilization: builder.query<IProjectUtilization, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project-utilization/${projectId}`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.UTILIZATION],
    }),
    getProjectUtilizationByDTO: builder.query<IProjectUtilization, GetProjectUtilizationByDtoRequest>({
      query: ({ projectId, buildings, existingBuildings }) => ({
        url: `/api/project-utilization/${projectId}/by-dto`,
        method: RequestMethod.POST,
        body: { buildings, existingBuildings },
      }),
    }),
    setConfidenceLevel: builder.mutation<void, SetConfidenceLevelRequest>({
      query: ({ projectId, confidenceLevel }) => ({
        url: `/api/project/${projectId}/confidence-level/${confidenceLevel}`,
        method: RequestMethod.PUT,
      }),
      invalidatesTags: [EBackendTag.PROJECT, EBackendTag.ENERGY],
    }),
    setLawZone: builder.mutation<void, SetLawZoneRequest>({
      query: ({ projectId, zoneId }) => ({
        url: `/api/project/${projectId}/law-zone/${zoneId}`,
        method: RequestMethod.PUT,
      }),
      invalidatesTags: [
        EBackendTag.PROJECT,
        EBackendTag.PROJECT_RULES_CHECK,
        EBackendTag.USER_PROJECT_RULES,
        EBackendTag.DATA_OVERVIEW,
      ],
    }),
    setProjectGeneralSettings: builder.mutation<void, ISetProjectGeneralSettingsRequest>({
      query: ({ projectId, body }) => ({
        url: `/api/project/${projectId}/general-settings`,
        method: RequestMethod.POST,
        body,
      }),
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useGetProjectQuery,
  useChangeProjectSettingsMutation,
  useGetProjectBkpCostQuery,
  useGetProjectEbkpCostQuery,
  useGetProjectRentIncomeQuery,
  useGetProjectSaleIncomeQuery,
  useGetProjectSiaAreasQuery,
  useGetProjectUtilizationQuery,
  useGetProjectUtilizationByDTOQuery,
  useGetProjectVolumesQuery,
  useSetConfidenceLevelMutation,
  useSetLawZoneMutation,
  useSetProjectGeneralSettingsMutation,
} = projectApiSlice;

export const useGetProjectQueryState = projectApiSlice.endpoints.getProject.useQueryState;

export const useGetProjectSiaAreasQueryState = projectApiSlice.endpoints.getProjectSiaAreas.useQueryState;

export const useGetProjectVolumesQueryState = projectApiSlice.endpoints.getProjectVolumes.useQueryState;

export const useGetProjectBkpCostQueryState = projectApiSlice.endpoints.getProjectBkpCost.useQueryState;

export const useGetProjectEbkpCostQueryState = projectApiSlice.endpoints.getProjectEbkpCost.useQueryState;

export const useGetProjectRentIncomeQueryState = projectApiSlice.endpoints.getProjectRentIncome.useQueryState;

export const useGetProjectSaleIncomeQueryState = projectApiSlice.endpoints.getProjectSaleIncome.useQueryState;
