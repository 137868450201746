import { ILngLat, ILv95, XY, XYZ } from 'domain/types/location/coordinates';
import proj4 from 'proj4';
import { sendError } from 'utils/sendError';

interface IProjection {
  name: string;
  projection: string;
}

export const projections: Record<string, IProjection> = {
  /*
   * WGS 84 -- WGS84 - World Geodetic System 1984, used in GPS
   * https://epsg.io/4326
   */
  wgs84: {
    name: 'EPSG:4326',
    projection: '+proj=longlat +datum=WGS84 +no_defs +type=crs',
  },
  /*
   * CH1903 / LV03 -- Swiss CH1903 / LV03
   * https://epsg.io/21781
   */
  lv03: {
    name: 'EPSG:21781',
    projection:
      '+proj=somerc +lat_0=46.9524055555556 +lon_0=7.43958333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs +type=crs',
  },
  /*
   * CH1903+ / LV95 -- Swiss
   * https://epsg.io/2056
   */
  lv95: {
    name: 'EPSG:2056',
    projection:
      '+proj=somerc +lat_0=46.9524055555556 +lon_0=7.43958333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs +type=crs',
  },
  /*
   * WGS 84 / Pseudo-Mercator -- Spherical Mercator, Google Maps, OpenStreetMap, Bing, ArcGIS, ESRI
   * https://epsg.io/3857
   */

  wgs84PseudoMercator: {
    name: 'EPSG:3857',
    projection:
      '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs',
  },
};

proj4.defs([
  [projections.wgs84.name, projections.wgs84.projection],
  [projections.lv03.name, projections.lv03.projection],
  [projections.lv95.name, projections.lv95.projection],
  [projections.wgs84PseudoMercator.name, projections.wgs84PseudoMercator.projection],
]);

export const wgsToLV95 = (point: ILngLat): ILv95 => {
  const [x, y] = proj4(projections.wgs84.projection, projections.lv95.projection, [point.lng, point.lat]);
  return { x, y };
};

export const lv95ToWgs = (point: ILv95): ILngLat => {
  const [lng, lat] = proj4(projections.lv95.projection, projections.wgs84.projection, [point.x, point.y]);
  return { lng, lat };
};

export const wgsToLocal = (coordinates: ILngLat[], ref: ILngLat): XY[] => {
  const localPoints: [number, number][] = [];

  if (ref) {
    const lv95Ref = wgsToLV95(ref);

    coordinates.forEach((coordinate) => {
      const point = wgsToLV95(coordinate);

      localPoints.push([point.x - lv95Ref.x, point.y - lv95Ref.y]);
    });
  }
  return localPoints;
};

export const localToWgs = (points: XY[], ref: ILngLat): XY[] => {
  const lv95Ref = wgsToLV95(ref);

  const coordinates: [number, number][] = [];

  points.forEach((point) => {
    const newPoints: [number, number] = [point[0] + lv95Ref.x, point[1] + lv95Ref.y];
    const wgs = lv95ToWgs({
      x: newPoints[0],
      y: newPoints[1],
    });

    coordinates.push([wgs.lng, wgs.lat]);
  });

  return coordinates;
};

// Default precision for Amenti coordinates
export const roundCoordinates = <T extends number | XY | XYZ>(coordinates: T): T => {
  if (typeof coordinates === 'number') {
    return Math.round(coordinates * 1000) / 1000 as T;
  } else if (Array.isArray(coordinates)) {
    return coordinates.map((coordinate) => Math.round(coordinate * 1000) / 1000) as T;
  }

  sendError('Invalid coordinates provided for rounding', {
    children: [
      {
        title: 'Coordinates',
        payload: coordinates,
      },
    ],
  });
  return coordinates;
};
