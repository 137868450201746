import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const GEONODE_SERVER_URL = import.meta.env.REACT_APP_GEONODE_SERVER_URL;

export const gisServerApi = createApi({
  reducerPath: 'gisServerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GEONODE_SERVER_URL,
  }),
  tagTypes: [],
  endpoints: () => ({}),
});
