type Title = string | number;
type Payload = any;
type LogType = 'error' | 'info' | 'log';

export interface ConsoleGroupChild {
  title: Title;
  payload: Payload;
  type?: LogType;
}

const styles = {
  logTitle: 'color: #000; background-color: #ebebeb; padding: 2px 5px; border-radius: 2px;',
  errorTitle: 'color: #ffe9e9; background-color: #dc362e; padding: 2px 5px; border-radius: 2px;',
  infoTitle: 'color: #004f7c; background-color: #e1f4ff; padding: 2px 5px; border-radius: 2px;',
};

const getStyle = (type?: LogType) => {
  switch (type) {
    case 'error':
      return styles.errorTitle;
    case 'info':
      return styles.infoTitle;
    default:
      return styles.logTitle;
  }
};

const DEFAULT_LOG_TYPE = 'info';

export class Console {
  static log(title: Title, ...payload: Payload) {
    console.log(`%c${title}`, styles.logTitle, ...payload);
  }

  static info(title: Title, ...payload: Payload) {
    console.info(`%c${title}`, styles.infoTitle, ...payload);
  }

  static error(title: Title, ...payload: Payload) {
    console.error(`%c${title}`, styles.errorTitle, ...payload);
  }

  static printGroup(title: Title, payload: Payload, children: ConsoleGroupChild[], type: LogType = DEFAULT_LOG_TYPE) {
    console.groupCollapsed(`%c${title}`, getStyle(type), payload);

    children.forEach((child) => {
      const childType = child.type ?? DEFAULT_LOG_TYPE;

      switch (childType) {
        case 'error':
          Console.error(`%c${child.title}`, styles.errorTitle, child.payload);
          break;
        case 'info':
          Console.info(`%c${child.title}`, styles.infoTitle, child.payload);
          break;
        default:
          Console.log(`%c${child.title}`, styles.logTitle, child.payload);
      }
    });

    console.groupEnd();
  }
}
