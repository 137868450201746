import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'state/resetAction';
import { v4 } from 'uuid';

export enum ECompareSection {
  PLOT = 'plot',
  BUILDINGS = 'buildings',
  USAGE = 'usage',
  BKP_COSTS = 'bkpCosts',
  EBKP_COSTS = 'ebkpCosts',
  SALE_INCOME = 'saleIncome',
  RENT_INCOME = 'rentIncome',
}

const sectionInitialState = {
  isOpen: true,
  show: true,
};

interface ICompareStateSectionBase {
  isOpen: boolean;
  show: boolean;
}

export type ICompareStateSections = Record<ECompareSection, ICompareStateSectionBase>;

export interface ICompareStateCard {
  id: string;
  projectId: string | null;
}

export interface CompareState {
  hoveredRow: string | null;
  cards: ICompareStateCard[];
  sections: ICompareStateSections;
}

const initialState: CompareState = {
  hoveredRow: null,
  cards: [
    {
      id: v4(),
      projectId: null,
    },
  ],
  sections: {
    [ECompareSection.PLOT]: sectionInitialState,
    [ECompareSection.BUILDINGS]: sectionInitialState,
    [ECompareSection.USAGE]: sectionInitialState,
    [ECompareSection.BKP_COSTS]: sectionInitialState,
    [ECompareSection.EBKP_COSTS]: sectionInitialState,
    [ECompareSection.SALE_INCOME]: sectionInitialState,
    [ECompareSection.RENT_INCOME]: sectionInitialState,
  },
};

export const compareSlice = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    // Generic
    clear: () => initialState,
    // Available
    clearCards: (state) => {
      state.cards = [];
    },
    addCard(state, action: PayloadAction<string | null>) {
      const newCards = state.cards.filter((card) => card.projectId !== action.payload);
      newCards.push({
        id: v4(),
        projectId: action.payload,
      });
      state.cards = newCards;
    },
    reorderCards(state, action: PayloadAction<{ startIndex: number; endIndex: number }>) {
      const { startIndex, endIndex } = action.payload;

      const result = Array.from(state.cards);

      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      state.cards = result;
    },
    updateCard(state, action: PayloadAction<ICompareStateCard>) {
      state.cards = state.cards.map((card) => {
        if (card.id === action.payload.id) {
          return {
            ...card,
            projectId: action.payload.projectId,
          };
        }
        return card;
      });
    },
    removeCard(state, action: PayloadAction<string>) {
      state.cards = state.cards.filter((card) => card.id !== action.payload);
    },
    toggleSectionIsOpen(state, action: PayloadAction<ECompareSection>) {
      state.sections[action.payload].isOpen = !state.sections[action.payload].isOpen;
    },
    setHoveredRow(state, action: PayloadAction<string | null>) {
      state.hoveredRow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  // Available
  clearCards,
  addCard,
  reorderCards,
  updateCard,
  removeCard,
  toggleSectionIsOpen,
  setHoveredRow,
} = compareSlice.actions;
