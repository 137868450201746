import { Grid2 } from '@mui/material';
import ShopProductSkeleton from 'components/features/ShopModal/views/ShopView/components/ShopProductSkeleton';
import React from 'react';

interface ShopProductsLoaderProps {
  items?: number;
}

const ShopProductsLoader = (props: ShopProductsLoaderProps) => {
  const { items = 3 } = props;

  return (
    <Grid2 container spacing={6}>
      {Array.from({ length: items }).map((_, index) => (
        <Grid2 size={12} key={index}>
          <ShopProductSkeleton />
        </Grid2>
      ))}
    </Grid2>
  );
};

export default ShopProductsLoader;
