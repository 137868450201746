import { RootState } from '../store';

export const exploreMapSelector = (state: RootState) => state.exploreMap;

export const mapRulerSelector = (state: RootState) => exploreMapSelector(state).ruler;

export const mapDrawSelector = (state: RootState) => exploreMapSelector(state).draw;

export const mapMergeSelector = (state: RootState) => exploreMapSelector(state).merge;

export const mapEditEnabledSelector = (state: RootState) =>
  mapDrawSelector(state).isOpen || mapMergeSelector(state).isOpen;

export const mapActivePlotEgridSelector = (state: RootState) => exploreMapSelector(state).merge.activePlotEgrid;

export const mapMutatedPlotSelector = (state: RootState) => exploreMapSelector(state).draw.mutatedPlot;

export const mapSavedMutatedPlotSelector = (state: RootState) => exploreMapSelector(state).draw.savedPlot;
