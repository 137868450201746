import { Clear, KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, Grid2, Skeleton, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import * as turf from '@turf/turf';
import { TInitializePurchasePaymentSuccessData } from 'api/dtos/payment/shop/dto/request';
import { EProductID } from 'api/dtos/payment/shop/enums';
import ModalContentWrapper from 'components/display/ModalContentWrapper';
import { IExploreCheckoutQueryParameters, IProjectCheckoutQueryParameters } from 'components/features/ShopModal/types';
import LoadingWrapper from 'components/utility/LoadingWrapper';
import { GENERAL } from 'constants/general';
import UNITS from 'constants/units';
import useMutablePlot from 'pages/App/Explore/hooks/useMutablePlot';
import { createProjectDTO, CreateProjectDTOProps } from 'pages/App/Explore/utils';
import useLocationData from 'pages/App/hooks/useLocationData';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router';
import { FULL_APP_PATHS } from 'routes/routers/app/appPaths';
import { FULL_SHOP_PATHS } from 'routes/routers/shop/shopPaths';
import { toast } from 'sonner';
import { projectIdSelector } from 'state/selectors/router';
import { shopBillingDetailsSelector, shopCartItemsSelector, shopCheckoutSelector } from 'state/selectors/shop';
import { useGetMergedPlotByEgridsQuery, useGetPlotByPointQuery } from 'state/services/backend/endpoints/map/cadastre';
import { useGetCheckoutCartQuery, useInitializePurchaseMutation } from 'state/services/backend/endpoints/shop/shop';
import { selectedZoneIdSelector } from 'state/slices/lawSelect';
import { removeItemToShopCartItems } from 'state/slices/shop';
import CircleButton from 'theme/components/inputs/CircleButton';
import TextIconButton from 'theme/components/inputs/TextIconButton';
import { formatNumber, getPrintValue } from 'utils/formatters';

import { getQueryParameters } from 'utils/url';
import VoucherRedeemer from '../../components/VoucherRedeemer';

const CheckoutView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocationData();
  const { address, plot, egrids, centerLng, centerLat } = location ?? {};
  const plots = egrids?.map((egrid) => ({
    egrid,
  }));
  const lngLat = { lng: centerLng ?? 0, lat: centerLat ?? 0 };
  const { data: plotByPointData } = useGetPlotByPointQuery(lngLat);
  const { data: mergedPlotByEgridsData } = useGetMergedPlotByEgridsQuery(egrids ?? skipToken);
  const { savedPlot, savedArea } = useMutablePlot();
  const projectId = useSelector(projectIdSelector);
  const selectedZoneId = useSelector(selectedZoneIdSelector);
  const billingDetails = useSelector(shopBillingDetailsSelector);
  const cartItems = useSelector(shopCartItemsSelector);
  const { voucher, datatrans } = useSelector(shopCheckoutSelector);
  const isDatatransLoading = datatrans.loading;

  const {
    data: checkoutCartData,
    isLoading: isGetCheckoutCartLoading,
    isFetching: isGetCheckoutCartFetching,
  } = useGetCheckoutCartQuery(
    plots
      ? {
          voucher,
          products: cartItems,
          plots,
        }
      : skipToken,
  );
  const { discount, userPrice } = checkoutCartData?.total ?? {};
  const { priceWithoutVat, vat, priceWithVat } = userPrice ?? {};

  const [initializePurchase, { isLoading: isInitializePurchaseLoading }] = useInitializePurchaseMutation();

  const handleRemoveProductFromCart = (productId: EProductID) => {
    dispatch(removeItemToShopCartItems(productId));
  };

  const handleBackNavigate = () => {
    navigate(FULL_SHOP_PATHS.BILLING_DETAILS.ROOT);
  };

  const handleInitializePurchase = () => {
    if (!egrids || !plots) {
      console.error('Could not initialize payment. Missing EGRIDs', {
        egrids,
        plots,
      });
      toast.error(t('general:somethingWentWrong'));
      return;
    }

    let paymentSuccessData: TInitializePurchasePaymentSuccessData;
    let successPath: string;
    let errorPath: string;
    let cancelPath: string;

    if (projectId) {
      const parameters = {
        projectId,
      } satisfies IProjectCheckoutQueryParameters;
      const queryParameters = getQueryParameters(parameters);

      paymentSuccessData = {
        projectId,
      };

      successPath = FULL_APP_PATHS.PAYMENT.SUCCESS;
      errorPath = generatePath(FULL_APP_PATHS.PROJECT.ROOT, { projectId }) + queryParameters;
      cancelPath = generatePath(FULL_APP_PATHS.PROJECT.ROOT, { projectId }) + queryParameters;
    } else {
      const parameters = {
        lng: centerLng ?? 0,
        lat: centerLat ?? 0,
        merge: egrids.length > 1 ? egrids : undefined,
        zoneId: selectedZoneId ?? undefined,
      } satisfies IExploreCheckoutQueryParameters;

      const queryParameters = getQueryParameters(parameters);

      const plotNumber = plot?.plotNumber;
      const cantonAbbr = plotByPointData?.features[0]?.properties?.cantonAbbr;

      let area: number | undefined;
      let polygon: number[][] | undefined;

      if (egrids.length > 1) {
        if (!mergedPlotByEgridsData) return;
        area = turf.area(mergedPlotByEgridsData.geometry);
        polygon = mergedPlotByEgridsData.geometry.coordinates[0];
      } else {
        if (!plotByPointData?.features[0]) return;
        area = turf.area(plotByPointData?.features[0].geometry);
        polygon = plotByPointData?.features[0].geometry.coordinates[0];
      }

      if (!address || !area || !polygon || !cantonAbbr || !plotNumber) {
        console.error('Could not initialize payment. Missing create project data.', {
          area,
          cantonAbbr,
          plotNumber,
          polygon,
        });
        toast.error(t('general:somethingWentWrong'));
        return;
      }

      const createProjectDTOProps: CreateProjectDTOProps = {
        address,
        area,
        cantonAbbr,
        egrids,
        lngLat,
        plotNumber,
        polygon,
        selectedZoneId,
      };

      if (savedPlot && savedArea) {
        createProjectDTOProps.polygon = savedPlot.geometry.coordinates[0];
        createProjectDTOProps.area = savedArea;
      }

      paymentSuccessData = createProjectDTO(createProjectDTOProps);

      successPath = FULL_APP_PATHS.PAYMENT.SUCCESS;
      errorPath = FULL_APP_PATHS.EXPLORE.ROOT + queryParameters;
      cancelPath = FULL_APP_PATHS.EXPLORE.ROOT + queryParameters;
    }

    initializePurchase({
      checkoutData: {
        address: billingDetails?.address ? billingDetails.address : '',
        city: billingDetails?.city ? billingDetails.city : '',
        company: billingDetails?.company ? billingDetails.company : '',
        email: billingDetails?.email ? billingDetails.email : '',
        fname: billingDetails?.fname ? billingDetails.fname : '',
        lname: billingDetails?.lname ? billingDetails.lname : '',
        plots,
        postal: billingDetails?.postal ? billingDetails.postal : '',
        products: cartItems,
        urls: {
          success: successPath,
          error: errorPath,
          cancel: cancelPath,
        },
        voucher,
      },
      paymentSuccessData,
    });
  };

  const isLoading = isGetCheckoutCartLoading || isGetCheckoutCartFetching || isInitializePurchaseLoading;

  return (
    <ModalContentWrapper maxWidth={500} sx={{ p: 6 }}>
      <LoadingWrapper loading={isLoading}>
        <Grid2 container spacing={3}>
          <Grid2 size={3}>
            <TextIconButton Icon={<KeyboardArrowLeft />} onClick={handleBackNavigate} />
          </Grid2>
          <Grid2 size={6}>
            <Typography variant='h5' component='h2' fontWeight={500} textAlign='center'>
              {t('shop:views.checkout.title')}
            </Typography>
          </Grid2>
          <Grid2 size={3} />
        </Grid2>
        <Box sx={{ mt: 8 }}>
          <Grid2 container spacing={3}>
            {isGetCheckoutCartLoading ? (
              <Grid2 size={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Skeleton width='60%' height={25} />
                  <Skeleton width='20%' height={25} />
                </Box>
              </Grid2>
            ) : checkoutCartData && checkoutCartData.products.length ? (
              <>
                {checkoutCartData.products.map((product) => (
                  <Grid2 size={12} key={product.id}>
                    <Box sx={{}}>
                      <Grid2 container spacing={3}>
                        <Grid2 size={8}>
                          <Typography variant='body1'>{t(`shop:products.${product.id}.title`)}</Typography>
                          {product.warning && (
                            <Typography variant='body2' color='orange.500'>
                              {t(`shop:productWarnings.${product.warning}`)} (
                              {t(`shop:products.${product.mainProductId}.title`)})
                            </Typography>
                          )}
                        </Grid2>
                        <Grid2 size={4}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Typography variant='body1'>
                              {!!product.discount && !product.discount?.error && (
                                <Box
                                  component='span'
                                  sx={{ ml: 1.5, display: 'block', textAlign: 'right', whiteSpace: 'nowrap' }}
                                >
                                  {getPrintValue(
                                    formatNumber(product.userPrice.priceWithoutVat / 100, {
                                      decimals: 2,
                                    }),
                                    UNITS.CURRENCY,
                                  )}
                                </Box>
                              )}
                              <Box
                                component='span'
                                sx={[
                                  {
                                    display: 'block',
                                    textAlign: 'right',
                                    whiteSpace: 'nowrap',
                                  },
                                  !!product.discount &&
                                    !product.discount.error && {
                                      color: 'grey.700',
                                      textDecoration: 'line-through',
                                      fontSize: 14,
                                    },
                                ]}
                              >
                                {getPrintValue(
                                  formatNumber(product.productPrice / 100, {
                                    decimals: 2,
                                  }),
                                  UNITS.CURRENCY,
                                )}
                              </Box>
                            </Typography>
                            <CircleButton
                              Icon={Clear}
                              IconSx={{ fontSize: 14 }}
                              size={24}
                              sx={{ ml: 3 }}
                              onClick={() => handleRemoveProductFromCart(product.id)}
                            />
                          </Box>
                        </Grid2>
                      </Grid2>
                    </Box>
                  </Grid2>
                ))}
              </>
            ) : (
              <Grid2 size={12}>
                <Typography variant='body1' color='textSecondary'>
                  {t('shop:views.checkout.cartIsEmpty')}
                </Typography>
              </Grid2>
            )}
            <Grid2 size={12}>
              <Divider />
            </Grid2>
            <Grid2 size={12}>
              <VoucherRedeemer isLoading={isGetCheckoutCartLoading} currentDiscount={discount} />
            </Grid2>
            <Grid2 size={12}>
              <Divider />
            </Grid2>
            <Grid2 size={6}>
              <Typography variant='body1' color='textSecondary'>
                {t('billing:general.subtotal')}
              </Typography>
            </Grid2>
            <Grid2 size={6}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  height: '100%',
                }}
              >
                {isGetCheckoutCartLoading ? (
                  <Skeleton width={60} height={25} />
                ) : (
                  <Typography variant='body1'>
                    {getPrintValue(
                      formatNumber(priceWithoutVat ? priceWithoutVat / 100 : 0, {
                        decimals: 2,
                      }),
                      UNITS.CURRENCY,
                      false,
                    )}
                  </Typography>
                )}
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Typography variant='body1' color='textSecondary'>
                {t('billing:general.vat', {
                  vat: formatNumber(GENERAL.VAT, {
                    decimals: 1,
                  }),
                })}
              </Typography>
            </Grid2>
            <Grid2 size={6}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  height: '100%',
                }}
              >
                {isGetCheckoutCartLoading ? (
                  <Skeleton width={60} height={25} />
                ) : (
                  <Typography variant='body1'>
                    {getPrintValue(
                      formatNumber(vat ? vat / 100 : 0, {
                        decimals: 2,
                      }),
                      UNITS.CURRENCY,
                      false,
                    )}
                  </Typography>
                )}
              </Box>
            </Grid2>
            <Grid2 size={12}>
              <Divider />
            </Grid2>
            <Grid2 size={6}>
              <Typography variant='body1' color='textSecondary'>
                {t('billing:general.total')}
              </Typography>
            </Grid2>
            <Grid2 size={6}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  height: '100%',
                }}
              >
                {isGetCheckoutCartLoading ? (
                  <Skeleton width={60} height={25} />
                ) : (
                  <Typography variant='body1' fontWeight={500}>
                    {getPrintValue(
                      formatNumber(priceWithVat ? priceWithVat / 100 : 0, {
                        decimals: 2,
                      }),
                      UNITS.CURRENCY,
                    )}
                  </Typography>
                )}
              </Box>
            </Grid2>
            <Grid2 size={12}>
              <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  size='large'
                  disabled={isDatatransLoading || !checkoutCartData?.products.length}
                  onClick={handleInitializePurchase}
                >
                  {isDatatransLoading ? (
                    <Box display='flex' justifyContent='center'>
                      <CircularProgress size={21} color='inherit' />
                    </Box>
                  ) : (
                    t('billing:modals.payment.cart.buttonText')
                  )}
                </Button>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </LoadingWrapper>
    </ModalContentWrapper>
  );
};

export default CheckoutView;
