import { addListener, createListenerMiddleware } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'state/store';

export const appListener = createListenerMiddleware();

export const startAppListening = appListener.startListening.withTypes<RootState, AppDispatch>();
export type AppStartListening = typeof startAppListening;

export const addAppListener = addListener.withTypes<RootState, AppDispatch>();
export type AppAddListener = typeof addAppListener;
