import { Grid2, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShopProductsMissing = () => {
  const { t } = useTranslation();

  return (
    <Grid2 container spacing={6}>
      <Grid2 size={12}>
        <Typography variant='body2' color='textSecondary'>
          {t('shop:general.noProductsInCategory')}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default ShopProductsMissing;
