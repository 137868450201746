import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutVisibilityKeySelector } from 'state/selectors/layout';
import { closeAllLayoutsVisibility, closeLayoutVisibility, openLayoutVisibility } from 'state/slices/layout';
import { RootState } from 'state/store';
import { getLayoutPathString } from './util';

interface useLayoutVisibilityProps {
  path: string[];
  initialOpen?: boolean;
}

const useLayoutVisibility = (props: useLayoutVisibilityProps) => {
  const { path, initialOpen = false } = props;

  const dispatch = useDispatch();

  const key = useMemo(() => getLayoutPathString(path), [path]);

  const memoizedLayoutVisibilityKeySelector = useMemo(() => layoutVisibilityKeySelector, []);
  const layoutVisibilityState = useSelector((state: RootState) => memoizedLayoutVisibilityKeySelector(state, key));

  const close = useCallback(() => dispatch(closeLayoutVisibility(key)), [dispatch, key]);
  const open = useCallback(() => dispatch(openLayoutVisibility(key)), [dispatch, key]);
  const closeAll = useCallback((path: string[]) => dispatch(closeAllLayoutsVisibility(path)), [dispatch]);

  return useMemo(
    () => ({
      isOpen: layoutVisibilityState === undefined ? initialOpen : layoutVisibilityState,
      open,
      close,
      closeAll,
    }),
    [layoutVisibilityState, initialOpen, open, close, closeAll],
  );
};

export default useLayoutVisibility;
