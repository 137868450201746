import { Check, Remove } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid2,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { FahrlanderSubscriptionAccessRequest } from 'api/dtos/fahrlander-access/request';
import { IFahrlanderFunctions } from 'api/dtos/fahrlander-functions/response';
import FieldErrorMessage from 'components/inputs/FieldErrorMessage';
import LoadingWrapper from 'components/utility/LoadingWrapper';
import useUser from 'hooks/useUser';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LargeField from 'theme/components/inputs/LargeField';

const styles = {
  formControlLabel: {
    m: 0,
    maxWidth: '100%',
    '& .MuiCheckbox-root': {
      p: 0,
      m: 0,
      mr: 3,
    },
    '& .MuiFormControlLabel-label': {
      typography: 'body2',
    },
  },
};

interface FahrlanderRequestFormProps {
  fahrlanderAccess: IFahrlanderFunctions;
  onSubmit: (data: FahrlanderSubscriptionAccessRequest) => void;
  status: QueryStatus;
  isLoading: boolean;
  disabled?: boolean;
}

const FahrlanderRequestForm = (props: FahrlanderRequestFormProps) => {
  const { fahrlanderAccess, isLoading, status, disabled, onSubmit } = props;

  const { t } = useTranslation();

  const { user } = useUser();

  const sections = useMemo(() => {
    if (!fahrlanderAccess) return [];

    return Object.entries(fahrlanderAccess).map(([key, value]) => ({
      id: key,
      rows: Object.entries(value).map(([key, value]) => ({
        id: key,
        status: value,
      })),
    }));
  }, [fahrlanderAccess]);

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FahrlanderSubscriptionAccessRequest>({
    defaultValues: {
      hasAccount: false,
      email: user?.email,
    },
  });

  const onSubmitHandler = (data: FahrlanderSubscriptionAccessRequest) => {
    onSubmit(data);
  };

  const state = watch();

  const hasChosenFields = Object.values(state.functions ?? {})
    .flatMap((section) => Object.values(section))
    .some(Boolean);

  return (
    <LoadingWrapper loading={isLoading}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid2 container spacing={4.5}>
          {!disabled && (
            <>
              <Grid2 size={12}>
                <Controller
                  name='hasAccount'
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      exclusive
                      color='tertiary'
                      size='small'
                      fullWidth
                      value={field.value}
                      onChange={(_, value) => field.onChange(value)}
                      sx={{
                        '& .MuiToggleButton-root': {
                          height: 40,
                        },
                      }}
                    >
                      <ToggleButton value={false}>{t('general:iDontHaveAnAccount')}</ToggleButton>
                      <ToggleButton value={true}>{t('general:iAlreadyHaveAnAccount')}</ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </Grid2>
              {state.hasAccount && (
                <Grid2 size={12}>
                  <LargeField
                    {...register('email', { required: t('forms:fields.email.required') })}
                    label={`${t('forms:fields.email.label')} (FPRE)`}
                    type='email'
                    fullWidth
                    required
                    error={!!errors.email}
                  />
                  <FieldErrorMessage errors={errors} name='email' sx={{ mt: 1 }} />
                </Grid2>
              )}
            </>
          )}
          {sections.map((section) => {
            return (
              <Grid2 size={12} key={section.id}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant='body1' fontWeight={500} gutterBottom>
                    {t(`integrations:partners.fahrlander.topics.${section.id}.title`)}
                  </Typography>
                </Box>
                {section.rows.map((row) => {
                  const title = t(`integrations:partners.fahrlander.topics.${section.id}.functions.${row.id}.title`);

                  return (
                    <Box key={row.id} sx={{ display: 'flex', alignItems: 'center', py: 1.5 }}>
                      {row.status === true ? (
                        <>
                          <Check sx={{ fontSize: 20, color: 'green.500', mr: 3 }} />
                          <Typography variant='body2' sx={{ minWidth: 0 }} noWrap>
                            {title}
                          </Typography>
                        </>
                      ) : disabled ? (
                        <>
                          <Remove sx={{ fontSize: 20, color: 'grey.500', mr: 3 }} color='inherit' />
                          <Typography variant='body2' sx={{ minWidth: 0 }} noWrap>
                            {title}
                          </Typography>
                        </>
                      ) : (
                        <FormControlLabel
                          // @ts-ignore
                          {...register(`functions.${section.id}.${row.id}`)}
                          control={<Checkbox color='secondary' size='small' />}
                          sx={styles.formControlLabel}
                          label={
                            <Typography variant='body2' sx={{ minWidth: 0 }} noWrap>
                              {title}
                            </Typography>
                          }
                        />
                      )}
                    </Box>
                  );
                })}
              </Grid2>
            );
          })}
          {!disabled && (
            <Grid2 size={12}>
              <Box sx={{ my: 3 }}>
                <Button type='submit' variant='contained' color='secondary' disabled={!hasChosenFields}>
                  {t('integrations:requestAccess')}
                </Button>
                {status === 'rejected' && (
                  <Typography variant='body2' color='error' sx={{ mt: 3 }}>
                    {t('general:somethingWentWrong')}
                  </Typography>
                )}
              </Box>
            </Grid2>
          )}
        </Grid2>
      </form>
    </LoadingWrapper>
  );
};

export default FahrlanderRequestForm;
