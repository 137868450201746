import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SKIP_AUTH_HEADER_KEY } from 'state/services/backend/constants';
import { EBackendTag } from 'state/services/backend/types';
import { prepareHeadersWithAuthToken } from 'state/utils/headers';

export const backendApi = createApi({
  reducerPath: 'backendApi',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.REACT_APP_API_MAIN_URL,
    prepareHeaders: async (headers) => {
      if (headers.has(SKIP_AUTH_HEADER_KEY)) return headers;

      let tokenizedHeaders: Headers;
      try {
        tokenizedHeaders = await prepareHeadersWithAuthToken(headers);
      } catch (e) {
        tokenizedHeaders = headers;
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has('puppeteerAccess')) {
        tokenizedHeaders.set('Puppeteer-Access', urlParams.get('puppeteerAccess') as string);
      }

      return tokenizedHeaders;
    },
  }),
  tagTypes: [
    EBackendTag.APARTMENTS,
    EBackendTag.APARTMENTS_FIT,
    EBackendTag.AREAS,
    EBackendTag.BUILDINGS,
    EBackendTag.BUILDINGS_USAGE,
    EBackendTag.BUILDING_APARTMENTS,
    EBackendTag.BUILDING_DEFAULTS,
    EBackendTag.COSTS,
    EBackendTag.CREDITS,
    EBackendTag.DATA_OVERVIEW,
    EBackendTag.DIMENSION_IMAGES,
    EBackendTag.EBKP_COSTS,
    EBackendTag.ENERGY,
    EBackendTag.FAHRLANDER_BUILDING_SETTINGS,
    EBackendTag.FAHRLANDER_FUNCTIONS,
    EBackendTag.FAHRLANDER_PLOT_ACCESS,
    EBackendTag.FAHRLANDER_PROJECT_ACCESS,
    EBackendTag.FAHRLANDER_SUBSCRIPTION_ACCESS,
    EBackendTag.IMAGES,
    EBackendTag.INCOMES,
    EBackendTag.INVALID_AFTER_SAVE,
    EBackendTag.KEE_VALUE_CALCULATION_SETTINGS,
    EBackendTag.KEE_VALUE_SUBSCRIPTION_ACCESS,
    EBackendTag.PARKING_LOTS,
    EBackendTag.PARKING_LOTS_RESULTS,
    EBackendTag.PARKING_LOT_FINANCE,
    EBackendTag.PROJECT,
    EBackendTag.PROJECTS,
    EBackendTag.PROJECT_AREAS,
    EBackendTag.PROJECT_COLLABORATORS,
    EBackendTag.PROJECT_COST,
    EBackendTag.PROJECT_ERRORS,
    EBackendTag.PROJECT_EXISTING_BUILDINGS,
    EBackendTag.PROJECT_INCOME,
    EBackendTag.PROJECT_LANDSCAPE,
    EBackendTag.PROJECT_MAP_FEATURES,
    EBackendTag.PROJECT_MODIFIED_TERRAIN,
    EBackendTag.PROJECT_NOTES,
    EBackendTag.PROJECT_RULES_CHECK,
    EBackendTag.PROJECT_VOLUMES,
    EBackendTag.PURCHASE_INFO,
    EBackendTag.SOLAR_IRRADIATION,
    EBackendTag.USER,
    EBackendTag.USER_PROJECT_RULES,
    EBackendTag.UTILITIES,
    EBackendTag.UTILIZATION,
    EBackendTag.VISUALIZATION,
    EBackendTag.WITH_LANGUAGE,
  ],
  endpoints: () => ({}),
});
