import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'state/resetAction';

export interface LawSelectState {
  selectedZoneId: number | null;
}

const initialState: LawSelectState = {
  selectedZoneId: null,
};

export const lawSelectSlice = createSlice({
  name: 'lawSelect',
  initialState,
  reducers: {
    clear: () => initialState,
    setSelectedZoneId(state, action: PayloadAction<LawSelectState['selectedZoneId']>) {
      state.selectedZoneId = action.payload;
    },
    clearSelectedZoneState(state) {
      state.selectedZoneId = null;
    },
  },
  selectors: {
    selectedZoneIdSelector: (state) => state.selectedZoneId,
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const { setSelectedZoneId, clearSelectedZoneState } = lawSelectSlice.actions;

export const { selectedZoneIdSelector } = lawSelectSlice.selectors;
