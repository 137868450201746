import { useMemo } from 'react';
import { useLocation } from 'react-router';

const getCurrentPath = (pathname: string, depth: number) => {
  const parts = pathname.split('/').filter(Boolean);

  const path = parts[depth];

  return path || '';
};

const useCurrentPath = (depth: number | number[]) => {
  const { pathname } = useLocation();

  return useMemo(() => {
    if (!Array.isArray(depth)) {
      const part = getCurrentPath(pathname, depth);

      return {
        currentPath: part,
        currentPathParts: [part],
      };
    }

    const parts = depth.map((d) => getCurrentPath(pathname, d)).filter(Boolean);

    return {
      currentPath: parts.join('/'),
      currentPathParts: parts,
    };
  }, [depth, pathname]);
};

export default useCurrentPath;
