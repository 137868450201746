import { Auth0ContextInterface } from '@auth0/auth0-react';

class Auth0 {
  public static getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'];
  public static getIdTokenClaims: Auth0ContextInterface['getIdTokenClaims'];

  public static setGetAccessTokenSilently = (
    getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  ) => {
    this.getAccessTokenSilently = getAccessTokenSilently;
  };

  public static setIdTokenClaims = (getIdTokenClaims: Auth0ContextInterface['getIdTokenClaims']) => {
    this.setIdTokenClaims = getIdTokenClaims;
  };
}

export default Auth0;
