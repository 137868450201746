import { useAuth0 } from '@auth0/auth0-react';
import Auth0 from 'components/features/Authentication/auth0';
import { useEffect } from 'react';

const Auth0ClassProvider = () => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    Auth0.setGetAccessTokenSilently(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    Auth0.setIdTokenClaims(getIdTokenClaims);
  }, [getIdTokenClaims]);

  return null;
};

export default Auth0ClassProvider;
