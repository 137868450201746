export interface UserResponse {
  id: string;
  email: string;
  language: string;
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  postcode: string;
  city: string;
  phone: string;
  logoUrl: string;
  notifications: {
    facadeDelete: boolean | null;
  };
  permissions: Auth0Permissions;
}

export type Auth0Permissions = EAuth0Permission[];

export enum EAuth0Permission {
  ADMIN_DEV_TOOLS = 'admin:devTools',
  ADMIN_LAW = 'admin:law',
  ADMIN_USERS = 'admin:users',
  APP_EMPLOYEE = 'app:employee',
  APP_TESTER = 'app:tester',
}
