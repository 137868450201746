import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { gtagPageView, setTrafficType, setUserId } from 'utils/trackers/analytics';

const GoogleAnalyzer = () => {
  const { user, isEmployee } = useUser();
  const { id } = user ?? {};

  const location = useLocation();

  useEffect(() => {
    setTrafficType(isEmployee);
    if (id) setUserId(id);
  }, [id]);

  useEffect(() => {
    gtagPageView(location.pathname, location.search);
  }, [location]);

  return null;
};

export default GoogleAnalyzer;
