import { StandardTextFieldProps, SxProps, TextField, Theme } from '@mui/material';
import React, { ForwardedRef } from 'react';
import styles from './styles';

interface LargeFieldProps extends StandardTextFieldProps {
  sx?: SxProps<Theme>;
}

const LargeField = React.forwardRef((props: LargeFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { label, sx, slotProps, ...otherProps } = props;

  return (
    <TextField
      ref={ref}
      label={label}
      sx={[
        styles.textField,
        !!label && {
          '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
              pt: 6.5,
            },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      slotProps={{
        ...slotProps,
        inputLabel: {
          ...slotProps?.inputLabel,
          shrink: true,
        },
      }}
      {...otherProps}
    />
  );
});

export default LargeField;
