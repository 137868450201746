import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import i18n from 'i18next';
import { truncate } from 'lodash-es';
import { toast } from 'sonner';
import theme from 'theme';

const isDevelopment = import.meta.env.REACT_APP_DOMAIN !== 'production';

const isToastEnabled = !new URLSearchParams(window.location.search).has('hideErrors');

const actionButtonStyle = {
  color: theme.palette.red[100],
  backgroundColor: theme.palette.red[600],
};

const createToastAction = (action: unknown) => ({
  label: 'Copy',
  onClick: () => {
    navigator.clipboard.writeText(JSON.stringify(action, null, 2));
  },
});

interface EndpointMetadata {
  endpointName: string;
  type: 'query' | 'mutation';
}

interface ErrorData {
  message?: string;
}

interface RejectedValueAction {
  payload: FetchBaseQueryError & {
    data?: ErrorData;
    originalStatus?: number;
  };
  meta: {
    arg: EndpointMetadata;
    requestStatus: 'rejected';
    requestId: string;
    rejectedWithValue: true;
  };
  type: string;
}

const isRejectedAction = (action: unknown): action is RejectedValueAction => isRejectedWithValue(action);

const truncateText = (text?: string) => truncate(text, { length: 500 });

export const toastMiddleware: Middleware = () => (next) => (action) => {
  next(action);

  if (isRejectedAction(action) && isToastEnabled) {
    if (isDevelopment) {
      const error = action.payload;
      if (!error) return;

      const description = typeof error.data === 'object' ? error.data?.message : error.data;

      toast.error(
        `Error${action?.payload?.originalStatus ? ` ${action?.payload?.originalStatus}` : ''}: ${action.meta.arg.endpointName}`,
        {
          id: `error-${action?.payload?.originalStatus}-${action?.meta?.arg?.endpointName}`,
          description: truncateText(description),
          action: createToastAction(action),
          actionButtonStyle,
        },
      );
    } else {
      if (action.meta.arg.type === 'mutation') {
        const errorMessage =
          typeof action.payload.data === 'object' ? action.payload.data?.message : i18n.t('general:somethingWentWrong');
        toast.error(truncateText(errorMessage));
      }
    }
  }
};
