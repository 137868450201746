import { createSelector } from '@reduxjs/toolkit';
import { UserResponse } from 'api/dtos/user/response';
import { userApiSlice } from 'state/services/backend/endpoints/user/user';

export const userResultSelector = userApiSlice.endpoints.getUser.select(undefined);

const emptyData: UserResponse = {
  id: '',
  email: '',
  language: '',
  firstName: '',
  lastName: '',
  company: '',
  street: '',
  postcode: '',
  city: '',
  phone: '',
  logoUrl: '',
  notifications: {
    facadeDelete: null,
  },
  permissions: [],
};

export const userSelector = createSelector(userResultSelector, (userResult) => userResult?.data ?? emptyData);
