import { UpdateUserDetailsRequest, UpdateUserNotificationsRequest } from 'api/dtos/user/request';
import { UserResponse } from 'api/dtos/user/response';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { RequestMethod } from 'types/request';

export const userApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserResponse, void>({
      query: () => ({
        url: '/api/users',
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.USER],
    }),
    setUserDetails: builder.mutation<void, UpdateUserDetailsRequest>({
      query: (body) => ({
        url: '/api/users/details',
        method: RequestMethod.PUT,
        body,
      }),
      invalidatesTags: [EBackendTag.USER],
    }),
    setUserLogo: builder.mutation<void, FormData>({
      query: (body) => ({
        url: '/api/users/logo',
        method: RequestMethod.PUT,
        body,
      }),
      invalidatesTags: [EBackendTag.USER],
    }),
    deleteUserLogo: builder.mutation<void, void>({
      query: () => ({
        url: '/api/users/logo',
        method: RequestMethod.DELETE,
      }),
      invalidatesTags: [EBackendTag.USER],
    }),
    setUserNotifications: builder.mutation<void, UpdateUserNotificationsRequest>({
      query: (body) => ({
        url: '/api/users/notifications',
        method: RequestMethod.PUT,
        body,
      }),
      invalidatesTags: [EBackendTag.USER],
    }),
  }),
});

export const {
  useGetUserQuery,
  useSetUserDetailsMutation,
  useSetUserLogoMutation,
  useDeleteUserLogoMutation,
  useSetUserNotificationsMutation,
} = userApiSlice;
