import { Box, Button, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import useModals from 'hooks/useModals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';
import { EModal } from 'types/enums/Modal';

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    px: 6,
    py: 9,
  },
};

const Error = () => {
  const dispatch = useDispatch();
  const { closeModal } = useModals();

  const { t } = useTranslation();

  const handleResetError = () => {
    dispatch(closeModal(EModal.SHOP));
  };

  return (
    <Box sx={styles.wrapper}>
      <Typography variant='body2' color='textSecondary' textAlign='center'>
        {t('general:error')}
      </Typography>
      <Button variant='outlined' sx={{ mt: 3 }} onClick={handleResetError}>
        {t('general:tryAgain')}
      </Button>
    </Box>
  );
};

const ShopWrapper = () => {
  return (
    <Sentry.ErrorBoundary fallback={() => <Error />}>
      <Outlet />
    </Sentry.ErrorBoundary>
  );
};

export default ShopWrapper;
