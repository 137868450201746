import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TParkingLots } from 'domain/types/parking';
import undoable, { combineFilters, excludeAction } from 'redux-undo';
import { resetAll } from 'state/resetAction';
import { TUndoRedoOptions } from 'state/slices/types';

export interface ParkingLotsState {
  parkingLots: TParkingLots;
}

const initialState: ParkingLotsState = {
  parkingLots: {},
};

export const parkingLotsSlice = createSlice({
  name: 'parkingLots',
  initialState,
  reducers: {
    // Generic
    clear: () => initialState,
    clearHistory: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    undo: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    redo: (_state, _action: PayloadAction<TUndoRedoOptions>) => {},
    // Available
    setParkingLots: (state, action) => {
      state.parkingLots = action.payload;
    },
    setParkingLotsWithoutHistory: (state, action) => {
      state.parkingLots = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  setParkingLots,
  setParkingLotsWithoutHistory,
  clearHistory: clearParkingLotsHistory,
  undo: undoParking,
  redo: redoParking,
} = parkingLotsSlice.actions;

export const parkingLotsReducer = undoable(parkingLotsSlice.reducer, {
  filter: combineFilters(
    (action) => action.type.startsWith(parkingLotsSlice.name + '/'),
    excludeAction([
      // Generic
      parkingLotsSlice.actions.clear.type,
      parkingLotsSlice.actions.clearHistory.type,
      parkingLotsSlice.actions.undo.type,
      parkingLotsSlice.actions.redo.type,
      // Available
      parkingLotsSlice.actions.setParkingLotsWithoutHistory.type,
    ]),
  ),
  clearHistoryType: parkingLotsSlice.actions.clearHistory.type,
  undoType: parkingLotsSlice.actions.undo.type,
  redoType: parkingLotsSlice.actions.redo.type,
});
