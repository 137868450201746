import { MoreHoriz } from '@mui/icons-material';
import { Box, CircularProgress, Grid2, Typography } from '@mui/material';
import React, { useId, useState } from 'react';
import PanelAccordion from 'theme/components/display/PanelAccordion';
import CircleButton from 'theme/components/inputs/CircleButton';
import ContextMenu from 'theme/components/inputs/ContextMenu';
import { IMenuItem } from 'theme/components/inputs/ContextMenu/ContextMenuItem';
import styles from './styles';

export interface ObjectCardProps {
  centerContent?: React.ReactNode;
  icon?: React.ReactNode | ((open: boolean) => React.ReactNode);
  children?: React.ReactNode;
  handleSelect?: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  isDuplicateOrRemoveLoading?: boolean;
  isLoading?: boolean;
  open?: boolean;
  menuOptions?: IMenuItem[];
  rightContent?: React.ReactNode;
  rightContentVisible?: boolean;
  selected?: boolean;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  toggleOpen?: () => void;
  headerClick?: 'select' | 'toggle';
}

const ObjectCard = (props: ObjectCardProps) => {
  const {
    centerContent,
    children,
    handleSelect,
    headerClick = 'select',
    icon,
    isDuplicateOrRemoveLoading,
    isLoading,
    menuOptions = [],
    open,
    rightContent,
    rightContentVisible,
    selected,
    subtitle,
    title,
    toggleOpen,
  } = props;

  const id = useId();

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const isClickable = !!toggleOpen || !!handleSelect;

  return (
    <Box
      sx={[
        styles.objectCard,
        styles.objectCardRightButtonsHover(id),
        isClickable && styles.objectCardClickable,
        !!selected && styles.objectCardSelected,
      ]}
    >
      <PanelAccordion
        open={!!open}
        openOnlyWithArrow
        onClick={toggleOpen}
        unmountOnExit
        header={
          <Box
            sx={[
              styles.objectCardHeader,
              isClickable && styles.objectCardHeaderClickable,
              (!children || !toggleOpen) && styles.objectCardHeaderNoContent,
              !!isLoading && styles.objectCardLoading,
              !!isDuplicateOrRemoveLoading && styles.objectCardDeleteLoading,
            ]}
            onClick={headerClick === 'select' ? handleSelect : toggleOpen}
          >
            <Grid2 container spacing={3} alignItems='center' sx={{ width: '100%' }}>
              <Grid2 size={centerContent ? 5 : 10}>
                <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 32 }}>
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', width: 30, ml: 0.5, mr: 3, color: 'grey.400' }}>
                      <CircularProgress size={24} color='inherit' />
                    </Box>
                  ) : (
                    icon && (
                      <Box sx={[styles.objectIcon, !!selected && styles.objectIconSelected]}>
                        {typeof icon === 'function' ? icon(!!open) : icon}
                      </Box>
                    )
                  )}
                  <Box display='flex' flexDirection='column' sx={styles.objectTitleWrapper}>
                    <Typography sx={styles.objectTitle} noWrap title={typeof title === 'string' ? title : undefined}>
                      {title}
                    </Typography>
                    {subtitle &&
                      (typeof subtitle === 'string' ? (
                        <Typography sx={styles.objectSubtitle} noWrap title={subtitle}>
                          {subtitle}
                        </Typography>
                      ) : (
                        subtitle
                      ))}
                  </Box>
                </Box>
              </Grid2>
              {centerContent && <Grid2 size={5}>{centerContent}</Grid2>}
              <Grid2 size={2}>
                {(menuOptions.length > 0 || rightContent) && (
                  <Box
                    className={`rightButtons rightButtons--${id}`}
                    sx={[
                      styles.rightButtons,
                      (open || isContextMenuOpen || !!rightContentVisible) && styles.rightButtonsOpen,
                    ]}
                  >
                    {rightContent}
                    {menuOptions.length > 0 && (
                      <ContextMenu
                        onOpen={() => setIsContextMenuOpen(true)}
                        onClose={() => setIsContextMenuOpen(false)}
                        menuItems={menuOptions}
                      >
                        <CircleButton
                          Icon={MoreHoriz}
                          IconSx={{ fontSize: 20 }}
                          loading={isLoading || isDuplicateOrRemoveLoading}
                          disabled={isLoading || isDuplicateOrRemoveLoading}
                        />
                      </ContextMenu>
                    )}
                  </Box>
                )}
              </Grid2>
            </Grid2>
          </Box>
        }
      >
        {children}
      </PanelAccordion>
    </Box>
  );
};

export default ObjectCard;
