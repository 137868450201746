import { useAuth0 } from '@auth0/auth0-react';
import LogoSpinner from 'components/utility/LogoSpinner';
import { AMENTI_AUTH_RETURN_TO_SESSION_KEY, getAmentiAuthReturnTo } from 'pages/AuthCallback/utils';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import { FULL_APP_PATHS } from 'routes/routers/app/appPaths';

const AuthCallback = () => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  const returnTo = getAmentiAuthReturnTo();

  useEffect(() => {
    if (isAuthenticated && user?.email_verified) {
      sessionStorage.removeItem(AMENTI_AUTH_RETURN_TO_SESSION_KEY);
    }
  }, [isAuthenticated]);

  return isLoading ? (
    <LogoSpinner />
  ) : isAuthenticated ? (
    <Navigate to={returnTo} replace />
  ) : (
    <Navigate to={FULL_APP_PATHS.AUTHENTICATION.LOGIN} replace />
  );
};

export default AuthCallback;
