import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDesktop, isTablet } from 'react-device-detect';
import { resetAll } from 'state/resetAction';

const pathMatches = (path: string[], statePath: string[]) => {
  for (const i in statePath) {
    if (path[i] !== '*' && statePath[i] !== path[i]) {
      return false;
    }
  }

  return true;
};

interface ISetLayoutViewAction {
  key: string;
  view: any;
}

const initialState: LayoutState = {
  view: {},
  visibility: {
    sidebar: isDesktop || isTablet,
  },
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    clear: () => initialState,
    openLayoutVisibility(state, action: PayloadAction<string>) {
      state.visibility[action.payload] = true;
    },
    closeLayoutVisibility(state, action: PayloadAction<string>) {
      state.visibility[action.payload] = false;
    },
    closeAllLayoutsVisibility(state, action: PayloadAction<string[]>) {
      const path = action.payload;
      Object.keys(state.visibility).forEach((key) => {
        const statePath = key.split('_');
        if (pathMatches(path, statePath)) {
          state.visibility[key] = false;
        }
      });
    },
    openSidebar(state) {
      state.visibility.sidebar = true;
    },
    closeSidebar(state) {
      state.visibility.sidebar = false;
    },
    setLayoutView(state, action: PayloadAction<ISetLayoutViewAction>) {
      state.view[action.payload.key] = action.payload.view;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export type LayoutViewState = Record<string, any>;

export type LayoutVisibilityState = Record<string, boolean>;

export type LayoutState = {
  view: LayoutViewState;
  visibility: LayoutVisibilityState;
};

export const {
  openLayoutVisibility,
  closeLayoutVisibility,
  closeAllLayoutsVisibility,
  openSidebar,
  closeSidebar,
  setLayoutView,
} = layoutSlice.actions;
