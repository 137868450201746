import { createSlice } from '@reduxjs/toolkit';
import { Params } from 'react-router';

const initialState: RouterState = {
  params: undefined,
};

export const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setParams(state, action) {
      state.params = action.payload;
    },
  },
});

export interface RouterState {
  params?: Params;
}
