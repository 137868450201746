import { alpha, Box, Button, Link, Typography } from '@mui/material';
import ProductTypeSwitch from 'components/features/ShopModal/components/ProductTypeSwitch/ProductTypeSwitch';
import useShopCurrentPath from 'components/features/ShopModal/hooks/useShopCurrentPath';
import useModals from 'hooks/useModals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { FULL_SHOP_PATHS, SHOP_PATHS } from 'routes/routers/shop/shopPaths';
import { CircleArrowUp } from 'theme/icons';
import { EModal } from 'types/enums/Modal';

const ShopProjectView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setModal } = useModals();
  const navigate = useNavigate();
  const { currentPath } = useShopCurrentPath(2);

  const handleOpenSubscriptionModal = () => {
    dispatch(
      setModal({
        modal: EModal.SUBSCRIPTION,
        page: null,
        open: true,
        backButtonAction: () => {
          dispatch(
            setModal({
              modal: EModal.SHOP,
              open: true,
              page: FULL_SHOP_PATHS.SHOP.ROOT + '/' + SHOP_PATHS.SHOP.PROJECT.FULL_PRODUCT,
            }),
          );
        },
      }),
    );
  };

  return (
    <Box sx={{ p: 6 }}>
      <Box sx={{ mb: 6 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='h5' component='h2' fontWeight={500} gutterBottom>
            {t('shop:views.shop.project.title')}
          </Typography>
          <Button
            component={Link}
            color='tertiary'
            variant='outlined'
            href='https://www.amenti.ch/request-video'
            target='_blank'
            size='small'
          >
            {t('billing:modals.payment.info.demoButtonText')}
          </Button>
        </Box>
        <Typography variant='body2' color='textSecondary' sx={{ mb: 6 }}>
          {t('shop:views.shop.project.description')}
        </Typography>
        <ProductTypeSwitch
          value={currentPath}
          options={[
            {
              value: SHOP_PATHS.SHOP.PROJECT.FULL_PRODUCT,
              label: t('shop:views.shop.project.fullProductButton'),
            },
            {
              value: SHOP_PATHS.SHOP.PROJECT.SEPARATE_PRODUCTS,
              label: t('shop:views.shop.project.separateProductsButton'),
            },
          ]}
          onChange={(_, path) => {
            navigate(path);
          }}
        />
      </Box>
      <Box
        variant='outlined'
        component={Button}
        onClick={handleOpenSubscriptionModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          p: 3,
          pr: 4,
          mt: 6,
          width: '100%',
          '&:hover': {
            border: 1,
            borderColor: 'blue.300',
            backgroundColor: (theme) => alpha(theme.palette.blue[500], 0.05),
          },
        }}
      >
        <CircleArrowUp sx={{ color: 'blue.500', mr: 3 }} />
        <Typography variant='body2' fontWeight={500} textAlign='left' sx={{ fontSize: 12, color: 'blue.500' }}>
          {t('billing:modals.payment.info.subscriptionButtonText')}
        </Typography>
      </Box>
      <Outlet />
    </Box>
  );
};

export default ShopProjectView;
