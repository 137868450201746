import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EMapLineType } from 'components/features/Map/types';
import { EDatasetTopic } from 'components/features/Plot/PlotRestrictions/types';
import { Feature, FeatureCollection, LineString, Polygon } from 'geojson';
import cloneDeep from 'lodash/cloneDeep';
import { resetAll } from 'state/resetAction';

export enum EMapType {
  CADASTRE = 'cadastre',
  SATELLITE = 'satellite',
}

export type FeatureId = string | number;

export interface MapState {
  selectedFeatureIds: FeatureId[];
  controls: {
    visibility: Record<EDatasetTopic, boolean>;
    type: EMapType;
    terrain: boolean;
  };
  toolbox: {
    mode:
      | 'view'
      | 'edit-modify'
      | 'edit-transform'
      | 'draw-polygon'
      | 'draw-90-polygon'
      | 'draw-linestring'
      | 'fill'
      | 'edit-section-cut';
    plugins: {
      measurements: {
        active: boolean;
      };
      snap: {
        active: boolean;
      };
      offset: {
        active: boolean;
        amount: number;
      };
    };
  };
  history: {
    initial: FeatureCollection<Polygon | LineString> | undefined;
    current: FeatureCollection<Polygon | LineString> | undefined;
    undoStack: FeatureCollection<Polygon | LineString>[];
    redoStack: FeatureCollection<Polygon | LineString>[];
  };
  staticFeatures: {
    [key: string]: Feature<LineString | Polygon>[];
  };
}

export type MapMode = MapState['toolbox']['mode'];
export type MapPlugin = keyof MapState['toolbox']['plugins'];

export const DEFAULT_VISIBILITY_CONTROLS = {
  ...Object.values(EDatasetTopic).reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {} as Record<EDatasetTopic, boolean>,
  ),
  [EDatasetTopic.FOREST_DISTANCE_LINES]: true,
  [EDatasetTopic.ROAD_DISTANCE_LINES]: true,
  [EDatasetTopic.WATER_DISTANCE_LINES]: true,
};

const initialState: MapState = {
  selectedFeatureIds: [],
  controls: {
    visibility: DEFAULT_VISIBILITY_CONTROLS,
    type: EMapType.CADASTRE,
    terrain: false,
  },
  toolbox: {
    mode: 'view',
    plugins: {
      measurements: {
        active: false,
      },
      snap: {
        active: false,
      },
      offset: {
        active: false,
        amount: 1,
      },
    },
  },
  history: {
    initial: undefined,
    current: undefined,
    undoStack: [],
    redoStack: [],
  },
  staticFeatures: {},
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    clear: () => initialState,
    setSelectedFeatureIds(state, action: PayloadAction<FeatureId[]>) {
      state.selectedFeatureIds = action.payload;
    },
    toggleVisibilityControl(state, action: PayloadAction<EDatasetTopic>) {
      state.controls.visibility[action.payload] = !state.controls.visibility[action.payload];
    },
    setVisibilityControlsToDefault(state) {
      state.controls.visibility = DEFAULT_VISIBILITY_CONTROLS;
    },
    setVisibilityControl(state, action: PayloadAction<{ type: EDatasetTopic; value: boolean }>) {
      state.controls.visibility[action.payload.type] = action.payload.value;
    },
    setMapType(state, action: PayloadAction<EMapType>) {
      state.controls.type = action.payload;
    },
    setMapTerrain(state, action: PayloadAction<boolean>) {
      state.controls.terrain = action.payload;
    },
    setMode(state, action: PayloadAction<MapMode>) {
      state.toolbox.mode = action.payload;
    },
    setInitial(state, action: PayloadAction<FeatureCollection<Polygon | LineString>>) {
      state.history.initial = action.payload;
      state.history.current = action.payload;
    },
    clearHistory(state) {
      state.history.current = state.history.initial;
      state.history.undoStack = [];
      state.history.redoStack = [];
    },
    undo(state) {
      if (state.history.undoStack.length > 0) {
        const undoState = cloneDeep(state.history.undoStack.pop());
        const current = state.history.current;
        if (undoState && current) {
          state.history.redoStack.push(current);
          state.history.current = undoState;
        }
      }
    },
    redo(state) {
      if (state.history.redoStack.length > 0) {
        const redoState = cloneDeep(state.history.redoStack.pop());
        const current = state.history.current;
        if (redoState && current) {
          state.history.undoStack.push(cloneDeep(current));
          state.history.current = redoState;
        }
      }
    },
    removeFeatureById(state, action: PayloadAction<string | number>) {
      if (!state.history.current) return;

      const index = state.history.current.features.findIndex((feature) => feature.id === action.payload);
      if (index === -1) return;

      mapSlice.caseReducers.commit(state, mapSlice.actions.commit(state.history.current));
      state.history.current.features.splice(index, 1);
    },
    setFeatureTypeById(state, action: PayloadAction<{ id: string; type: EMapLineType }>) {
      const { id, type } = action.payload;
      if (!state.history.current) return;
      mapSlice.caseReducers.commit(state, mapSlice.actions.commit(state.history.current));
      state.history.current.features = state.history.current.features.map((feature) => {
        if (feature.id === id || feature.properties?.id === id) {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              type,
            },
          };
        }
        return feature;
      });

      if (!state.history.current) return;
    },
    setFeaturePropertyById(
      state,
      action: PayloadAction<{ id: string; property: string; value: string | number | boolean }>,
    ) {
      if (!state.history.current) return;

      const { id, property, value } = action.payload;

      state.history.current.features = state.history.current.features.map((feature) => {
        if (feature.id === id || feature.properties?.id === id) {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              [property]: value,
            },
          };
        }

        return feature;
      });
    },
    setStaticFeatures(state, action: PayloadAction<{ key: string; value: Feature<LineString | Polygon>[] }>) {
      state.staticFeatures[action.payload.key] = action.payload.value || [];
    },
    commit(state, action: PayloadAction<FeatureCollection<LineString | Polygon>>) {
      const current = state.history.current;
      if (!current) return;
      state.history.undoStack.push(cloneDeep(current));
      state.history.current = action.payload;
      state.history.redoStack = [];
    },
    togglePlugin(state, action: PayloadAction<MapPlugin>) {
      state.toolbox.plugins[action.payload].active = !state.toolbox.plugins[action.payload].active;
    },
    disableAllPlugins(state) {
      Object.values(state.toolbox.plugins).forEach((plugin) => {
        plugin.active = false;
      });
    },
    enablePlugins(state, action: PayloadAction<MapPlugin[]>) {
      action.payload.forEach((plugin) => {
        state.toolbox.plugins[plugin].active = true;
      });
    },
    disablePlugins(state, action: PayloadAction<MapPlugin[]>) {
      for (const plugin of action.payload) {
        Object.assign(state.toolbox.plugins[plugin], initialState.toolbox.plugins[plugin]);
      }
    },
    setPluginValues(state, action: PayloadAction<{ plugin: MapPlugin; values: Record<any, any> }>) {
      const { plugin, values } = action.payload;
      Object.assign(state.toolbox.plugins[plugin], values);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const {
  clearHistory,
  commit,
  disableAllPlugins,
  disablePlugins,
  enablePlugins,
  redo,
  setFeaturePropertyById,
  setFeatureTypeById,
  setInitial,
  setMapType,
  setMode,
  setPluginValues,
  setSelectedFeatureIds,
  setStaticFeatures,
  removeFeatureById,
  setVisibilityControlsToDefault,
  togglePlugin,
  toggleVisibilityControl,
  undo,
} = mapSlice.actions;
