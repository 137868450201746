import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, SxProps } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import TeaserCollapse from '../TeaserCollapse';
import styles from './styles';

export interface PanelAccordionProps {
  open: boolean;
  onClick?: (event: SyntheticEvent) => void;
  header: ((isOpen: boolean) => React.ReactNode) | React.ReactNode;
  children?: React.ReactNode;
  variant?: 'standard' | 'outlined';
  hasCollapse?: boolean;
  collapsedSize?: number;
  teaser?: boolean;
  unmountOnExit?: boolean;
  openOnlyWithArrow?: boolean;
  hasPadding?: boolean;
  showArrow?: boolean;
  ArrowSx?: SxProps;
  ArrowOpenSx?: SxProps;
  sx?: SxProps;
}

const PanelAccordion = (props: PanelAccordionProps) => {
  const {
    open,
    onClick,
    header,
    children,
    variant = 'standard',
    hasCollapse = true,
    collapsedSize = 100,
    hasPadding = true,
    openOnlyWithArrow,
    unmountOnExit = true,
    teaser,
    ArrowSx,
    ArrowOpenSx,
    showArrow = true,
    sx,
  } = props;

  const hasArrow = showArrow && onClick !== undefined && !!children && hasCollapse;

  return (
    <Box sx={[variant === 'outlined' && styles.outlinedAccordion, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box
        role='button'
        tabIndex={0}
        sx={[
          styles.header,
          onClick !== undefined && !!children && hasCollapse && styles.headerClickable,
          variant === 'outlined' && styles.outlinedHeader,
        ]}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onClick !== undefined) {
            onClick(event);
          }
        }}
        onClick={openOnlyWithArrow ? undefined : onClick}
      >
        {typeof header === 'function' ? header(open) : header}
        {hasArrow && (
          <Box
            sx={[
              styles.arrow,
              !hasPadding && styles.arrowRight,
              ...(Array.isArray(ArrowSx) ? ArrowSx : [ArrowSx]),
              ...(Array.isArray(ArrowOpenSx) ? ArrowOpenSx : [ArrowOpenSx]),
            ]}
            onClick={openOnlyWithArrow ? onClick : undefined}
          >
            <KeyboardArrowDown sx={[styles.arrowIcon, open && styles.arrowIconActive]} />
          </Box>
        )}
      </Box>
      {children && (
        <TeaserCollapse
          open={open}
          hasCollapse={hasCollapse}
          teaser={teaser}
          collapsedSize={collapsedSize}
          unmountOnExit={unmountOnExit}
          onClick={open ? undefined : onClick}
        >
          <Box sx={[styles.content, variant === 'outlined' && styles.contentPadding]}>{children}</Box>
        </TeaserCollapse>
      )}
    </Box>
  );
};

interface ControlledPanelAccordionProps extends Omit<PanelAccordionProps, 'open' | 'onClick'> {
  defaultOpen?: boolean;
  onClick?: null;
}

const ControlledPanelAccordion = (props: ControlledPanelAccordionProps) => {
  const { onClick, ...otherProps } = props;

  const [open, setOpen] = useState<boolean>(!!otherProps.defaultOpen);

  const handleToggleOpen = () => setOpen((open) => !open);

  return <PanelAccordion open={open} onClick={onClick === null ? undefined : handleToggleOpen} {...otherProps} />;
};

export { ControlledPanelAccordion };
export default PanelAccordion;
