import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, Polygon } from 'geojson';
import { resetAll } from 'state/resetAction';
import { Nullable } from 'types/utils';

export interface ICadastre {
  egris_egrid: string;
  area: number;
  canton: string;
  property_number: string;
}

export enum EMapRulerType {
  DISTANCE = 'distance',
  AREA = 'area',
}

export interface ExploreMapState {
  isCentered: boolean;
  selectedPlots: Feature<Polygon, ICadastre>[];
  isSearch: boolean;
  draw: {
    isOpen: boolean;
    mutatedPlot: Nullable<Feature<Polygon, ICadastre>>;
    savedPlot: Nullable<Feature<Polygon, ICadastre>>;
    measurementActive: boolean;
  };
  ruler: {
    isOpen: boolean;
    type: EMapRulerType | null;
    measuredDistance: number;
    measuredArea: number;
  };
  merge: {
    isOpen: boolean;
    activePlotEgrid: Nullable<string>;
  };
}

const savedPlot = sessionStorage.getItem('saved_plot');
sessionStorage.removeItem('saved_plot');

const initialState: ExploreMapState = {
  isCentered: true,
  selectedPlots: [],
  isSearch: false,
  draw: {
    isOpen: false,
    mutatedPlot: null,
    savedPlot: savedPlot ? JSON.parse(savedPlot as any) : null,
    measurementActive: false,
  },
  ruler: {
    isOpen: false,
    type: null,
    measuredDistance: 0,
    measuredArea: 0,
  },
  merge: {
    isOpen: false,
    activePlotEgrid: null,
  },
};

export const exploreMapSlice = createSlice({
  name: 'exploreMap',
  initialState,
  reducers: {
    clear: () => initialState,
    setActivePlotEgrid(state, action: PayloadAction<string>) {
      state.merge.activePlotEgrid = action.payload;
    },
    openRuler(state) {
      state.ruler.isOpen = true;
      state.ruler.type = EMapRulerType.DISTANCE;
    },
    closeRuler(state) {
      state.ruler.isOpen = false;
      state.ruler.measuredDistance = 0;
      state.ruler.measuredArea = 0;
      state.ruler.type = null;
    },
    setRulerType(state, action: PayloadAction<EMapRulerType | null>) {
      state.ruler.type = action.payload;
    },
    setMutatedPlot(state, action: PayloadAction<ExploreMapState['draw']['mutatedPlot']>) {
      state.draw.mutatedPlot = action.payload;
    },
    saveMutatedPlot(state) {
      sessionStorage.setItem('saved_plot', JSON.stringify(state.draw.mutatedPlot));
      state.draw.savedPlot = state.draw.mutatedPlot;
    },
    setSavedPlot(state, action: PayloadAction<ExploreMapState['draw']['savedPlot']>) {
      state.draw.savedPlot = action.payload;
    },
    setSelectedPlots(state, action: PayloadAction<Feature<Polygon, ICadastre>[]>) {
      state.selectedPlots = action.payload;
    },
    enableMapDraw(state) {
      state.draw.isOpen = true;
    },
    disableMapDraw(state) {
      state.draw.isOpen = false;
      state.draw.mutatedPlot = null;
    },
    setMergeOpen(state) {
      state.merge.isOpen = true;
    },
    setMergeClose(state) {
      state.merge.isOpen = false;
    },
    toggleMapMeasurement(state) {
      state.draw.measurementActive = !state.draw.measurementActive;
    },
    setIsSearch(state, action: PayloadAction<boolean>) {
      state.isSearch = action.payload;
    },
  },
  selectors: {
    exploreMapSelector: (state) => state,
    selectedPlotsSelector: (state) => state.selectedPlots,
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const { exploreMapSelector, selectedPlotsSelector } = exploreMapSlice.selectors;

export const {
  openRuler,
  closeRuler,
  setRulerType,
  setMergeOpen,
  setMergeClose,
  setSelectedPlots,
  saveMutatedPlot,
  setSavedPlot,
  setMutatedPlot,
  disableMapDraw,
  enableMapDraw,
  toggleMapMeasurement,
  setIsSearch,
} = exploreMapSlice.actions;
