import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { startAppListening } from 'state/listeners/appListener';
import { resetAll } from 'state/resetAction';
import { applicationPathSelector } from 'state/selectors/cityView';
import { buildingsSlice } from 'state/slices/buildings';
import { cityViewSlice } from 'state/slices/cityView';
import { landscapeSlice } from 'state/slices/landscape';
import { parkingLotsSlice } from 'state/slices/parkingLots';
import { terrainSlice } from 'state/slices/terrain';
import { TUndoRedoOptions } from 'state/slices/types';
import { EApplicationTopic } from 'types/applicationPath';

const TOPIC_HISTORY_CLEAR_ACTIONS: Record<
  EApplicationTopic,
  ActionCreatorWithOptionalPayload<TUndoRedoOptions> | null
> = {
  [EApplicationTopic.DEFAULT]: null,
  [EApplicationTopic.FORM]: buildingsSlice.actions.clearHistory,
  [EApplicationTopic.FACADE]: buildingsSlice.actions.clearHistory,
  [EApplicationTopic.USAGE]: buildingsSlice.actions.clearHistory,
  [EApplicationTopic.PHOTOVOLTAICS]: buildingsSlice.actions.clearHistory,
  [EApplicationTopic.LANDSCAPE]: landscapeSlice.actions.clearHistory,
  [EApplicationTopic.PARKING_LOTS]: parkingLotsSlice.actions.clearHistory,
  [EApplicationTopic.TERRAIN]: terrainSlice.actions.clearHistory,
};

export const setupDesignStepListeners = () => {
  // Clear history when switching between steps in the same topic
  startAppListening({
    predicate: cityViewSlice.actions._setApplicationPath.match,
    effect: (action, listenerApi) => {
      const state = listenerApi.getState();

      const { step: currentStep, topic: currentTopic } = applicationPathSelector(state);
      const { step: nextStep, topic: nextTopic } = action.payload;

      if (currentTopic === nextTopic && currentStep === nextStep) return;

      const clearHistory = TOPIC_HISTORY_CLEAR_ACTIONS[currentTopic];
      if (!clearHistory) return;

      listenerApi.dispatch(clearHistory());
    },
  });

  // Clear histories on resetAll
  startAppListening({
    predicate: resetAll.match,
    effect: (_, listenerApi) => {
      Object.values(TOPIC_HISTORY_CLEAR_ACTIONS).forEach((clearHistory) => {
        if (clearHistory) listenerApi.dispatch(clearHistory());
      });
    },
  });
};
