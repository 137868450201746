import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { FULL_APP_PATHS } from 'routes/routers/app/appPaths';
import RouterReduxConnector from '../RouterReduxConnector';

const PrivateRouteWrapper = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  if (!isAuthenticated) return <Navigate to={FULL_APP_PATHS.AUTHENTICATION.LOGIN} state={{ from: location }} replace />;

  return (
    <RouterReduxConnector>
      <Outlet />
    </RouterReduxConnector>
  );
};

export default PrivateRouteWrapper;
