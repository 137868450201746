import { Avatar, Box, Tab, Tabs, Theme } from '@mui/material';
import LogoFPRE from 'assets/images/partners/fpre.svg';
import LogoKeeValue from 'assets/images/partners/keevalue.svg';
import useShopCurrentPath from 'components/features/ShopModal/hooks/useShopCurrentPath';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { SHOP_PATHS } from 'routes/routers/shop/shopPaths';

const styles = {
  tabs: {
    m: 0,
    '& > div > .MuiTabs-indicator': {
      right: 'auto',
      left: 0,
      width: 3,
    },
  },
  largeTabs: {
    '& > div > .MuiTabs-indicator': {
      height: '30px !important',
    },
  },
  smallTabs: {
    minHeight: 0,
    '& .MuiTabs-indicator': {
      height: 'auto',
    },
  },
  tab: {
    textTransform: 'none',
    marginBottom: 2,
    opacity: 1,
    minWidth: 0,
    padding: 0,
    mb: 3,
    alignItems: 'flex-start',
    pl: 4.5,
    minHeight: 30,
    transition: (theme: Theme) => theme.transitions.create(['color']),
    '&:last-child': {
      mb: 0,
    },
  },
  largeTab: {
    fontSize: {
      xs: 16,
      md: 20,
    },
  },
  smallTab: {
    minHeight: 0,
    mb: 3,
  },
};

const StoreViewIntegrationsNavigation = () => {
  const { currentPath } = useShopCurrentPath(2);

  const navigate = useNavigate();

  const handleChangePath = (_: React.SyntheticEvent, path: string) => {
    navigate(`/shop/integrations/${path}`);
  };

  return (
    <Box sx={{ py: 3, pl: styles.tab.pl, bgcolor: 'grey.300', width: '100%' }}>
      <Tabs
        value={currentPath || SHOP_PATHS.SHOP.INTEGRATIONS.FPRE.ROOT}
        onChange={handleChangePath}
        orientation='vertical'
        indicatorColor='secondary'
        sx={[styles.tabs, styles.smallTabs]}
      >
        {/*<Tab label='Overview' sx={[styles.tab, styles.smallTab]} value={SHOP_PATHS.SHOP.INTEGRATIONS.OVERVIEW.ROOT} />*/}
        <Tab
          label={
            <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                variant='square'
                src={LogoFPRE}
                role='FPRE'
                sx={{
                  width: 20,
                  height: 20,
                  mr: 2,
                  p: 0.5,
                  bgcolor: 'common.white',
                  borderRadius: '50%',
                }}
              />
              FPRE
            </Box>
          }
          sx={[styles.tab, styles.smallTab]}
          value={SHOP_PATHS.SHOP.INTEGRATIONS.FPRE.ROOT}
        />
        <Tab
          label={
            <Box component='span' sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                variant='square'
                src={LogoKeeValue}
                role='KeeValue'
                sx={{
                  width: 20,
                  height: 20,
                  mr: 2,
                  p: 0.5,
                  bgcolor: 'common.white',
                  borderRadius: '50%',
                }}
              />
              KeeValue
            </Box>
          }
          sx={[styles.tab, styles.smallTab]}
          value={SHOP_PATHS.SHOP.INTEGRATIONS.KEEVALUE.ROOT}
        />
      </Tabs>
    </Box>
  );
};

const ShopViewNavigation = () => {
  const { t } = useTranslation();
  const { currentPath } = useShopCurrentPath(1);

  const navigate = useNavigate();

  const handleChangePath = (_: React.SyntheticEvent, path: string) => {
    navigate(path);
  };

  return (
    <Tabs
      value={currentPath || SHOP_PATHS.SHOP.PROJECT.ROOT}
      onChange={handleChangePath}
      orientation='vertical'
      indicatorColor='secondary'
      sx={[styles.tabs, styles.largeTabs]}
    >
      <Tab
        label={t('shop:views.shop.project.title')}
        sx={[styles.tab, styles.largeTab]}
        value={SHOP_PATHS.SHOP.PROJECT.ROOT}
      />
      <Tab
        sx={[styles.tab, styles.largeTab]}
        label={t('shop:views.shop.integrations.title')}
        value={SHOP_PATHS.SHOP.INTEGRATIONS.ROOT}
      />
      {currentPath === SHOP_PATHS.SHOP.INTEGRATIONS.ROOT && <StoreViewIntegrationsNavigation />}
    </Tabs>
  );
};

export default ShopViewNavigation;
