import { EFaceDirection, ISegmentListItem } from 'domain/types/building/Facade';
import { TFace } from 'domain/types/building/Face';
import { EFloorType } from 'domain/types/building/Floor';

export enum EPVActivationState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  MIXED = 'mixed',
}

export interface ISelectedRoofSegmentProperties {
  roofPvPercentages: number[];
}

export type TMonthlyNumberValues = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
];

export interface ISolarLimits {
  yearlyReduction: [number, number];
  yearlyIrradiation: [number, number];
  reductionMonthlyMin: TMonthlyNumberValues;
  reductionMonthlyMax: TMonthlyNumberValues;
  irradiationMonthlyMin: TMonthlyNumberValues;
  irradiationMonthlyMax: TMonthlyNumberValues;
}

export type ISolarSegments = Record<string, ISolarSegment>;

export interface ISolarSegment {
  yearlyIrradiation: number;
  yearlyIrradiationReduction: number;
  monthlyIrradiation: TMonthlyNumberValues;
  monthlyIrradiationReduction: TMonthlyNumberValues;
}

export interface IBuildingPvFloorWalls extends ISegmentListItem {
  floors: IPvFloorWallVariants[];
}

export interface IBuildingPvWall extends ISegmentListItem {
  id: string;
  direction: number;
  directionName: EFaceDirection;
  segmentsWithActivePv: ISegmentListItem;
  segmentsWithInactivePv: ISegmentListItem;
  segmentsWithNoPv: ISegmentListItem;
}

export interface IBuildingPvWallVariants extends ISegmentListItem {
  walls: IBuildingPvWall[];
}

export interface IPvFloorVariants extends ISegmentListItem {
  floorId: string;
  segmentsWithActivePv: ISegmentListItem;
  segmentsWithInactivePv: ISegmentListItem;
  segmentsWithNoPv: ISegmentListItem;
}

export interface IPvFloorWallVariants extends ISegmentListItem {
  floorId: string;
  floorNumber: number;
  floorType: EFloorType;
  faces: IPvFaceVariant[];
}

export interface IPvFaceVariant extends ISegmentListItem {
  direction: number;
  directionName: EFaceDirection;
  face: TFace;
  segmentsWithActivePv: ISegmentListItem;
  segmentsWithInactivePv: ISegmentListItem;
  segmentsWithNoPv: ISegmentListItem;
}

export interface IBuildingPvFloors extends ISegmentListItem {
  floors: IPvFloorVariants[];
}

export interface IBuildingPvVariants {
  totalSegmentCount: number;
  selectedSegmentCount: number;
  segmentIds: string[];
  segmentsWithActivePv: ISegmentListItem;
  segmentsWithInactivePv: ISegmentListItem;
  segmentsWithNoPv: ISegmentListItem;
}

export interface IRoofSegmentPvVariants extends ISegmentListItem {
  segmentPvVariants: Record<number, ISegmentListItem>;
}

export interface IRoofFacePvVariants extends ISegmentListItem {
  faceId: string;
  segmentPvVariants: IRoofSegmentPvVariants;
}

export enum ESolarExposureType {
  IRRADIATION = 'irradiation',
  SHADING_FACTOR = 'shadingFactor',
}

export enum ESolarExposureInterval {
  YEAR = 'year',
  MONTH = 'month',
}

export interface IPvSegment {
  power: number;
  pvArea: number;
  pvEfficiency: number;
}

export type TPvSegments = Record<string, IPvSegment>;
