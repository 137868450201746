import { ReportProblem } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useRouteError } from 'react-router';

interface BaseLayoutErrorProps {
  resetError: () => void;
}

const BaseLayoutError = (props: BaseLayoutErrorProps) => {
  const { resetError } = props;

  const { t } = useTranslation();

  const error = useRouteError();
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    console.error(error);
    /*
     * Handle dynamic import error and reload the page
     * Happens when a new version of the app is deployed and the user has the old version open.
     * The error is thrown when the old version tries to import a module that is not available in the new version
     * or the file has a different hash.
     */
    if (error instanceof Error && error.message.includes('Failed to fetch dynamically imported module')) {
      const params = new URLSearchParams(location.search);
      const refreshKey = 'refresh';
      const refresh = params.get(refreshKey);

      // Tries to refresh the page only once
      if (!refresh) {
        params.set(refreshKey, 'true');
        navigate(`${location.pathname}?${params.toString()}`, {
          replace: true,
        });

        setTimeout(() => window.location.reload(), 50);
      }
    }
  }, [error]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        px: 6,
        py: 9,
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ p: 4, maxWidth: 400 }}>
        <ReportProblem sx={{ mb: 4 }} />
        <Typography variant='body2' color='textSecondary'>
          {t('general:error')}
        </Typography>
        <Button variant='outlined' sx={{ mt: 6 }} onClick={resetError}>
          {t('general:tryAgain')}
        </Button>
      </Box>
    </Box>
  );
};

export default BaseLayoutError;
