import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ChangePasswordRequest } from 'state/services/auth0/types';
import { RequestMethod } from 'types/request';

export const auth0Api = createApi({
  reducerPath: 'auth0Api',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${import.meta.env.REACT_APP_AUTH0_DOMAIN}`,
  }),
  endpoints: (builder) => ({
    changePassword: builder.mutation<string, ChangePasswordRequest>({
      query: (body) => ({
        url: '/dbconnections/change_password',
        method: RequestMethod.POST,
        body: {
          client_id: import.meta.env.REACT_APP_AUTH0_CLIENT_ID,
          email: body.email,
          connection: 'Username-Password-Authentication',
        },
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useChangePasswordMutation } = auth0Api;
