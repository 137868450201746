import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetAddressByPointRequest, SearchAddressRequest, SearchPlotNrRequest } from 'api/dtos/geo-admin/request';
import { GetAddressByPointResponse, SearchAddressResponse, SearchPlotNrResponse } from 'api/dtos/geo-admin/response';
import { wgsToLV95 } from 'domain/utils/coordinates';

export const geoAdminApi = createApi({
  reducerPath: 'geoAdminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api3.geo.admin.ch/rest/services`,
  }),
  endpoints: (builder) => ({
    searchAddress: builder.query<SearchAddressResponse, SearchAddressRequest>({
      query: ({ address }) =>
        `/api/SearchServer?sr=2056&searchText=${encodeURIComponent(address)}&lang=de&type=locations`,
    }),
    searchByPlotNumber: builder.query<SearchPlotNrResponse, SearchPlotNrRequest>({
      query: ({ plotNr, canton }) =>
        `/api/SearchServer?sr=2056&searchText=${plotNr},${canton}&lang=de&type=locations&origins=parcel,gg25`,
    }),
    getAddressByPoint: builder.query<GetAddressByPointResponse, GetAddressByPointRequest>({
      query: ({ lng, lat }) => {
        const { x, y } = wgsToLV95({ lng, lat });

        return `/api/MapServer/identify?mapExtent=0,0,100,100&imageDisplay=100,100,100&tolerance=50&sr=2056&geometryType=esriGeometryPoint&geometry=${x},${y}&layers=all:ch.bfs.gebaeude_wohnungs_register&returnGeometry=true`;
      },
    }),
  }),
});

export const { useSearchAddressQuery, useSearchByPlotNumberQuery, useGetAddressByPointQuery } = geoAdminApi;
