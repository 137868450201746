import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ECameraType, EObjectType, ETerrainImageType } from 'cityview/types/cityView';
import { TBuildings } from 'domain/types/building/Building';
import { IBufferConstructionSettings } from 'domain/types/building/BuildingBuffers';
import { ESolarExposureInterval, ESolarExposureType } from 'domain/types/building/Pv';
import { ETerrainWidth } from 'domain/types/terrain';
import { EVegetationType } from 'domain/types/vegetation';
import isNil from 'lodash/isNil';
import { resetAll } from 'state/resetAction';
import {
  EApplicationMode,
  EApplicationOverrideTool,
  EApplicationStep,
  EApplicationTool,
  EApplicationTopic,
  ESelectionFilter,
  IApplicationPath,
} from 'types/applicationPath';
import { IGeneralSettings } from 'types/project/project';
import { Nullable } from 'types/utils';

export interface IActiveSwissTopoBuilding {
  buildingId: string;
  x: number;
  y: number;
}

export interface ICityViewGeneral {
  disableClickInteraction: boolean;
  isDesktop: boolean;
  applicationPath: IApplicationPath;
  overrideTool: EApplicationOverrideTool;
}

export interface ICityViewBuffers {
  bufferConstructionSettings: Nullable<IBufferConstructionSettings>;
}

export interface ICityViewMouse {
  isPersistingChanges: boolean;
  isMouseDown: boolean;
  isMouseEditing: boolean;
  isMouseOnGizmoDown: boolean;
  isTransformingCamera: boolean;
}

export interface CityViewState {
  buffers: ICityViewBuffers;
  existingBuildings: TBuildings;
  general: ICityViewGeneral;
  landscape: {
    addVegetationType: EVegetationType;
  };
  mouse: ICityViewMouse;
  selections: {
    selectedObjects: Record<string, EObjectType>;
  };
  settings: {
    settingsSection: {
      active: boolean;
    };
    clipping: {
      active: boolean;
      constant: number;
      angle: number;
    };
    existingBuildings: {
      active: boolean;
    };
    existingBuildingWireframes: {
      active: boolean;
    };
    terrain: {
      active: boolean;
      width: ETerrainWidth;
    };
    buffers: {
      active: boolean;
    };
    buildings: {
      active: boolean;
    };
    parkingLots: {
      active: boolean;
    };
    restrictions: {
      active: boolean;
    };
    vegetation: {
      active: boolean;
    };
    mapOverlay: {
      active: boolean;
      type: ETerrainImageType;
    };
    grid: {
      active: boolean;
      step: number;
      rotationAngle: number;
    };
    snapping: {
      active: boolean;
      threshold: number;
    };
    showFacadeInTerrainTopic: {
      active: boolean;
    };
    camera: {
      type: ECameraType;
    };
    sky: {
      active: boolean;
    };
    solarExposure: {
      open: boolean;
      active: boolean;
      type: ESolarExposureType;
      interval: ESolarExposureInterval;
      intervalMonth: number;
    };
    offsetTerrain: {
      active: boolean;
      heightFromTerrain: number | null;
    };
  };
}

const DEFAULT_PATH = {
  step: EApplicationStep.LOCATION,
  topic: EApplicationTopic.DEFAULT,
  mode: EApplicationMode.VIEW,
  tool: EApplicationTool.DEFAULT,
  selectionFilter: ESelectionFilter.NONE,
};

const initialState: CityViewState = {
  general: {
    disableClickInteraction: false,
    isDesktop: true,
    overrideTool: EApplicationOverrideTool.NONE,
    applicationPath: DEFAULT_PATH,
  },
  selections: {
    selectedObjects: {},
  },
  mouse: {
    isPersistingChanges: false,
    isMouseDown: false,
    isMouseEditing: false,
    isMouseOnGizmoDown: false,
    isTransformingCamera: false,
  },
  buffers: {
    bufferConstructionSettings: null,
  },
  existingBuildings: {},
  landscape: {
    addVegetationType: EVegetationType.MAPLE,
  },
  settings: {
    settingsSection: {
      active: false,
    },
    buildings: {
      active: true,
    },
    parkingLots: {
      active: true,
    },
    existingBuildings: {
      active: true,
    },
    existingBuildingWireframes: {
      active: false,
    },
    terrain: {
      active: true,
      width: ETerrainWidth.SMALL,
    },
    buffers: {
      active: false,
    },
    restrictions: {
      active: true,
    },
    vegetation: {
      active: true,
    },
    mapOverlay: {
      active: true,
      type: ETerrainImageType.ROADMAP,
    },
    grid: {
      active: false,
      step: 10,
      rotationAngle: 0,
    },
    snapping: {
      active: true,
      threshold: 0.7,
    },
    showFacadeInTerrainTopic: {
      active: false,
    },
    clipping: {
      active: false,
      constant: 0,
      angle: 0,
    },
    camera: {
      type: ECameraType.PERSPECTIVE,
    },
    sky: {
      active: true,
    },
    solarExposure: {
      open: false,
      active: false,
      type: ESolarExposureType.IRRADIATION,
      interval: ESolarExposureInterval.YEAR,
      intervalMonth: 6,
    },
    offsetTerrain: {
      active: false,
      heightFromTerrain: null,
    },
  },
};

export const cityViewSlice = createSlice({
  name: 'cityView',
  initialState,
  reducers: {
    // Generic
    clear: () => initialState,
    // Available
    setCityViewSettings: (state, action: PayloadAction<IGeneralSettings>) => {
      const {
        cityViewBuildingsActive,
        cityViewParkingLotsActive,
        cityViewExistingBuildingsActive,
        cityViewDemolishedBuildingsActive,
        cityViewTerrainActive,
        cityViewTerrainWidth,
        cityViewBuffersActive,
        cityViewRestrictionsActive,
        cityViewVegetationActive,
        cityViewMapOverlayActive,
        cityViewMapOverlayType,
        cityViewSnappingActive,
        cityViewSkyActive,
        cityViewShowFacadeInTerrainTopicActive,
      } = action.payload;

      if (!isNil(cityViewBuildingsActive)) state.settings.buildings.active = cityViewBuildingsActive;
      if (!isNil(cityViewParkingLotsActive)) state.settings.parkingLots.active = cityViewParkingLotsActive;
      if (!isNil(cityViewExistingBuildingsActive))
        state.settings.existingBuildings.active = cityViewExistingBuildingsActive;
      if (!isNil(cityViewDemolishedBuildingsActive))
        state.settings.existingBuildingWireframes.active = cityViewDemolishedBuildingsActive;
      if (!isNil(cityViewTerrainActive)) state.settings.terrain.active = cityViewTerrainActive;
      if (!isNil(cityViewTerrainWidth)) state.settings.terrain.width = cityViewTerrainWidth;
      if (!isNil(cityViewBuffersActive)) state.settings.buffers.active = cityViewBuffersActive;
      if (!isNil(cityViewRestrictionsActive)) state.settings.restrictions.active = cityViewRestrictionsActive;
      if (!isNil(cityViewVegetationActive)) state.settings.vegetation.active = cityViewVegetationActive;
      if (!isNil(cityViewMapOverlayActive)) state.settings.mapOverlay.active = cityViewMapOverlayActive;
      if (!isNil(cityViewMapOverlayType)) state.settings.mapOverlay.type = cityViewMapOverlayType;
      // if (!isNil(cityViewSnappingActive)) state.settings.snapping.active = cityViewSnappingActive;
      if (!isNil(cityViewSkyActive)) state.settings.sky.active = cityViewSkyActive;
      if (!isNil(cityViewShowFacadeInTerrainTopicActive))
        state.settings.showFacadeInTerrainTopic.active = cityViewShowFacadeInTerrainTopicActive;
    },
    toggleCityViewSettingsSectionVisibility: (state) => {
      state.settings.settingsSection.active = !state.settings.settingsSection.active;
    },
    setExistingBuildingsVisibility(state, action: PayloadAction<boolean>) {
      state.settings.existingBuildings.active = action.payload;
    },
    toggleExistingBuildingsVisibility(state) {
      state.settings.existingBuildings.active = !state.settings.existingBuildings.active;
    },
    setBuildingsVisibility(state, action: PayloadAction<boolean>) {
      state.settings.buildings.active = action.payload;
    },
    setExistingBuildingWireframesVisibility(state, action: PayloadAction<boolean>) {
      state.settings.existingBuildingWireframes.active = action.payload;
    },
    setTerrainVisibility(state, action: PayloadAction<boolean>) {
      state.settings.terrain.active = action.payload;
    },
    setParkingLotsVisibility(state, action: PayloadAction<boolean>) {
      state.settings.parkingLots.active = action.payload;
    },
    setRestrictionsVisibility(state, action: PayloadAction<boolean>) {
      state.settings.restrictions.active = action.payload;
    },
    setVegetationVisibility(state, action: PayloadAction<boolean>) {
      state.settings.vegetation.active = action.payload;
    },
    toggleVegetationVisibility(state) {
      state.settings.vegetation.active = !state.settings.vegetation.active;
    },
    setAddVegetationType: (state, action: PayloadAction<EVegetationType>) => {
      state.landscape.addVegetationType = action.payload;
    },
    setSnappingEnabled: (state, action: PayloadAction<boolean>) => {
      state.settings.snapping.active = action.payload;
    },
    toggleGridVisibility(state) {
      state.settings.grid.active = !state.settings.grid.active;
    },
    setGridStep(state, action: PayloadAction<number>) {
      state.settings.grid.step = action.payload;
    },
    setGridRotationAngle(state, action: PayloadAction<number>) {
      state.settings.grid.rotationAngle = action.payload;
    },
    setMapOverlayType(state, action: PayloadAction<ETerrainImageType>) {
      state.settings.mapOverlay.type = action.payload;
    },
    setTerrainWidth(state, action: PayloadAction<ETerrainWidth>) {
      state.settings.terrain.width = action.payload;
    },
    setCameraType(state, action: PayloadAction<ECameraType>) {
      state.settings.camera.type = action.payload;
    },
    setGridVisibility(state, action: PayloadAction<boolean>) {
      state.settings.grid.active = action.payload;
    },
    setShowFacadeInTerrainTopicVisibility(state, action: PayloadAction<boolean>) {
      state.settings.showFacadeInTerrainTopic.active = action.payload;
    },
    setOffsetTerrainVisibility(state, action: PayloadAction<boolean>) {
      state.settings.offsetTerrain.active = action.payload;
    },
    setOffsetTerrainHeight(state, action: PayloadAction<number | null>) {
      state.settings.offsetTerrain.heightFromTerrain = action.payload;
    },
    setSkyVisibility(state, action: PayloadAction<boolean>) {
      state.settings.sky.active = action.payload;
    },
    setSolarExposureVisibility(state, action: PayloadAction<boolean>) {
      state.settings.solarExposure.open = action.payload;
    },
    toggleSolarExposureVisibility(state) {
      state.settings.solarExposure.open = !state.settings.solarExposure.open;
    },
    setSolarExposureStatus(state, action: PayloadAction<boolean>) {
      state.settings.solarExposure.active = action.payload;
    },
    setSolarExposureType(state, action: PayloadAction<ESolarExposureType>) {
      state.settings.solarExposure.type = action.payload;
    },
    setSolarExposureInterval(state, action: PayloadAction<ESolarExposureInterval>) {
      state.settings.solarExposure.interval = action.payload;
    },
    setSolarExposureIntervalMonth(state, action: PayloadAction<number>) {
      state.settings.solarExposure.intervalMonth = action.payload;
    },
    setBufferConstructionSettings: (state, action: PayloadAction<IBufferConstructionSettings>) => {
      state.buffers.bufferConstructionSettings = action.payload;
    },
    setClippingEnabled: (state, action: PayloadAction<boolean>) => {
      state.settings.clipping.active = action.payload;
    },
    setClippingConstant: (state, action: PayloadAction<number>) => {
      state.settings.clipping.constant = action.payload;
    },
    setClippingAngle: (state, action: PayloadAction<number>) => {
      state.settings.clipping.angle = action.payload;
    },
    setOverrideTool: (state, action: PayloadAction<EApplicationOverrideTool>) => {
      state.general.overrideTool = action.payload;
    },
    setIsMouseDown: (state, action: PayloadAction<boolean>) => {
      state.mouse.isMouseDown = action.payload;
    },
    setIsMouseEditing: (state, action: PayloadAction<boolean>) => {
      state.mouse.isMouseEditing = action.payload;
    },
    setIsPersistingChanges: (state, action: PayloadAction<boolean>) => {
      state.mouse.isPersistingChanges = action.payload;
    },
    setIsTransformingCamera: (state, action: PayloadAction<boolean>) => {
      state.mouse.isTransformingCamera = action.payload;
    },
    setIsMouseOnGizmoDown: (state, action: PayloadAction<boolean>) => {
      state.mouse.isMouseOnGizmoDown = action.payload;
    },
    // Internal
    _setApplicationPath: (state, action: PayloadAction<IApplicationPath>) => {
      state.general.applicationPath = action.payload;
    },
    _selectObject: (state, action: PayloadAction<{ id: string; type: EObjectType }>) => {
      state.selections.selectedObjects[action.payload.id] = action.payload.type;
    },
    _addSelectedObjects: (state, action: PayloadAction<{ ids: string[]; type: EObjectType }>) => {
      action.payload.ids.forEach((id) => {
        state.selections.selectedObjects[id] = action.payload.type;
      });
    },
    _deselectObject: (state, action: PayloadAction<string>) => {
      delete state.selections.selectedObjects[action.payload];
    },
    _removeSelectedObjects: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((id) => {
        delete state.selections.selectedObjects[id];
      });
    },
    _deselectType: (state, action: PayloadAction<EObjectType>) => {
      Object.entries(state.selections.selectedObjects).forEach(([id, objectType]) => {
        if (objectType === action.payload) {
          delete state.selections.selectedObjects[id];
        }
      });
    },
    _clearAllSelections: (state) => {
      state.selections.selectedObjects = {};
    },
    _clearBuildingChildrenSelection: (state) => {
      const typesToClear = [
        EObjectType.FLOOR,
        EObjectType.SEGMENT,
        EObjectType.TRANSFORM_CORNER,
        EObjectType.TRANSFORM_WALL,
        EObjectType.BUFFER,
      ];

      Object.entries(state.selections.selectedObjects).forEach(([id, objectType]) => {
        if (typesToClear.includes(objectType)) {
          delete state.selections.selectedObjects[id];
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
    builder.addMatcher(
      (action) =>
        action.type.includes('/undo') || action.type.includes('/redo') || action.type.includes('/clearHistory'),
      (state, action) => {
        if (
          'payload' in action &&
          action.payload &&
          typeof action.payload === 'object' &&
          'clearSelections' in action.payload &&
          action.payload.clearSelections
        ) {
          state.selections.selectedObjects = {};
        }
      },
    );
  },
});

export const {
  // Available
  setCityViewSettings,
  setBuildingsVisibility,
  setCameraType,
  setGridRotationAngle,
  setGridStep,
  setGridVisibility,
  setOffsetTerrainVisibility,
  setOffsetTerrainHeight,
  setMapOverlayType,
  setSkyVisibility,
  setSolarExposureInterval,
  setSolarExposureIntervalMonth,
  setSolarExposureType,
  setSolarExposureVisibility,
  setSolarExposureStatus,
  setTerrainVisibility,
  setVegetationVisibility,
  setShowFacadeInTerrainTopicVisibility,
  toggleCityViewSettingsSectionVisibility,
  toggleSolarExposureVisibility,
  toggleExistingBuildingsVisibility,
  toggleGridVisibility,
  toggleVegetationVisibility,
  setBufferConstructionSettings,
  setClippingEnabled,
  setClippingConstant,
  setClippingAngle,
  setOverrideTool,
  setIsMouseDown,
  setIsMouseEditing,
  setAddVegetationType,
  setIsPersistingChanges,
  setIsTransformingCamera,
  setIsMouseOnGizmoDown,
  setTerrainWidth,
  // Internal
  _setApplicationPath,
} = cityViewSlice.actions;
