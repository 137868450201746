import { Box } from '@mui/material';
import ModalContentWrapper from 'components/display/ModalContentWrapper';
import ShopViewHeader from 'components/features/ShopModal/views/ShopView/components/ShopViewHeader';
import React from 'react';
import { Outlet } from 'react-router';
import ShopViewNavigation from './components/ShopViewNavigation';

const styles = {
  container: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    maxHeight: {
      xs: 'initial',
      sm: 'calc(100vh - 72px)',
    },
  },
  leftSide: {
    width: {
      xs: '100%',
      sm: 150,
      md: 200,
    },
    minWidth: {
      xs: 0,
      sm: 150,
      md: 200,
    },
    bgcolor: 'grey.200',
    py: 3,
    borderRight: 1,
    borderColor: 'grey.300',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0,
  },
  header: {
    height: 'auto',
    borderBottom: 1,
    borderColor: 'grey.300',
  },
  content: {
    flexGrow: 1,
    overflowY: 'auto',
  },
};

const ShopView = () => {
  return (
    <ModalContentWrapper>
      <Box sx={styles.container}>
        <Box sx={styles.leftSide}>
          <ShopViewNavigation />
        </Box>
        <Box sx={styles.rightSide}>
          <Box sx={styles.header}>
            <ShopViewHeader />
          </Box>
          <Box sx={styles.content}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </ModalContentWrapper>
  );
};

export default ShopView;
