import * as Sentry from '@sentry/react';
import BaseLayoutError from 'components/layouts/BaseLayout/BaseLayoutError';
import Modals from 'components/modals/Modals';
import Authenticator from 'components/utility/Authenticator';
import CookieBanner from 'components/utility/CookieBanner';
import Datatrans from 'components/utility/Datatrans';
import GoogleAnalyzer from 'components/utility/GoogleAnalyzer';
import LanguageDetector from 'components/utility/LanguageDetector';
import ReactRouter7Adapter from 'components/utility/ReactRouter7Adapter';
import Toaster from 'components/utility/Toaster';
import useUser from 'hooks/useUser';
import DeviceRestriction from 'pages/DeviceRestriction';
import React, { useEffect } from 'react';
import { MapProvider } from 'react-map-gl';
import { Outlet } from 'react-router';
import SiteHelmet from 'theme/components/utility/SiteHelmet';
import useDevice from 'theme/hooks/useDevice';
import { QueryParamProvider } from 'use-query-params';

const SentryContextConnector = () => {
  const { user } = useUser();

  useEffect(() => {
    Sentry.setUser(
      user
        ? {
            email: user.email,
          }
        : null,
    );

    return () => Sentry.setUser(null);
  }, [user]);

  return null;
};

const AuthenticatedContent = () => (
  <>
    <Modals />
    <Datatrans />
  </>
);

const Content = () => (
  <>
    <LanguageDetector />
    <Outlet />
  </>
);

const handleResetError = () => window.location.reload();

const AppWrapper = () => {
  const { isMobileLandscape } = useDevice();

  return (
    <QueryParamProvider adapter={ReactRouter7Adapter}>
      <Sentry.ErrorBoundary fallback={() => <BaseLayoutError resetError={handleResetError} />}>
        <MapProvider>
          <SiteHelmet />
          <SentryContextConnector />
          <GoogleAnalyzer />
          <Authenticator AuthenticatedContent={AuthenticatedContent} Content={Content} />
          {isMobileLandscape && <DeviceRestriction />}
          <CookieBanner />
          <Toaster />
        </MapProvider>
      </Sentry.ErrorBoundary>
    </QueryParamProvider>
  );
};

export default AppWrapper;
