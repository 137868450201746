import { GlobalStyles, Interpolation, Theme } from '@mui/material';
import useCurrentPath from 'hooks/useCurrentPath';
import React from 'react';
import { APP_PATHS } from 'routes/routers/app/appPaths';
import { Toaster as SonnerToaster, ToasterProps } from 'sonner';
import theme from 'theme';

const styles: Interpolation<Theme> = {
  '.sonner-toast': {
    fontFamily: theme.typography.fontFamily,
    '--normal-bg': theme.palette.grey[100],
    '--normal-text': theme.palette.grey[800],
    '--normal-border': theme.palette.grey[300],
    '--success-bg': theme.palette.green[200],
    '--success-text': theme.palette.green[700],
    '--success-border': theme.palette.green[300],
    '--warning-bg': theme.palette.orange[100],
    '--warning-text': theme.palette.orange[800],
    '--warning-border': theme.palette.orange[200],
    '--error-bg': theme.palette.red[50],
    '--error-text': theme.palette.red[600],
    '--error-border': theme.palette.red[100],
  },
};

const toastOptions: ToasterProps['toastOptions'] = {
  className: 'sonner-toast',
};

const Toaster = () => {
  const { currentPath } = useCurrentPath(0);

  const isViewerPath = currentPath === APP_PATHS.VIEWER.ROOT;
  if (isViewerPath) return;

  return (
    <>
      <GlobalStyles styles={styles} />
      <SonnerToaster richColors closeButton toastOptions={toastOptions} />
    </>
  );
};

export default Toaster;
