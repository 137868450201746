import { Grid2 } from '@mui/material';
import useShopData from 'components/features/ShopModal/hooks/useShopData';
import ShopProductsLoader from 'components/features/ShopModal/views/ShopView/components/ShopProductsLoader/ShopProductsLoader';
import ShopProductsMissing from 'components/features/ShopModal/views/ShopView/components/ShopProductsMissing';
import React from 'react';
import ProductFpreDesignKosten from '../../products/ProductFpreDesignKosten';
import ProductFpreLageGemeinde from '../../products/ProductFpreLageGemeinde';
import ProductFpreLageParzelle from '../../products/ProductFpreLageParzelle';
import ProductFpreProjektKosten from '../../products/ProductFpreProjektKosten';
import ProductFpreProjektRendite from '../../products/ProductFpreProjektRendite';

const ShopIntegrationsFpreSeparateProductsView = () => {
  const { data: shopData, isLoading } = useShopData();

  if (isLoading) return <ShopProductsLoader />;
  if (!shopData) return <ShopProductsMissing />;

  return (
    <Grid2 container spacing={6}>
      <Grid2 size={12}>
        <ProductFpreLageParzelle shopData={shopData} />
      </Grid2>
      <Grid2 size={12}>
        <ProductFpreLageGemeinde shopData={shopData} />
      </Grid2>
      <Grid2 size={12}>
        <ProductFpreDesignKosten shopData={shopData} />
      </Grid2>
      <Grid2 size={12}>
        <ProductFpreProjektKosten shopData={shopData} />
      </Grid2>
      <Grid2 size={12}>
        <ProductFpreProjektRendite shopData={shopData} />
      </Grid2>
    </Grid2>
  );
};

export default ShopIntegrationsFpreSeparateProductsView;
