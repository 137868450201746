import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import LogoSpinner from '../LogoSpinner';

interface AuthenticatorProps {
  AuthenticatedContent: React.ComponentType;
  Content: React.ComponentType;
}

const Authenticator = (props: AuthenticatorProps) => {
  const { AuthenticatedContent, Content } = props;

  const { isLoading, isAuthenticated } = useAuth0();

  return isLoading ? (
    <LogoSpinner />
  ) : (
    <>
      {isAuthenticated && <AuthenticatedContent />}
      <Content />
    </>
  );
};

export default Authenticator;
