import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

interface CircleProps {
  color?: string;
  background?: string;
  stroke?: string;
  size?: number;
  sx?: SxProps<Theme>;
}

const Circle = (props: CircleProps) => {
  const { color = 'grey.300', background, stroke, size = 15, sx } = props;

  return (
    <Box
      component='span'
      sx={[
        {
          display: 'inline-block',
          width: size,
          height: size,
          minWidth: size,
          minHeight: size,
          borderRadius: '50%',
          bgcolor: color,
          background,
        },
        stroke && {
          border: 1,
          borderColor: stroke,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default Circle;
