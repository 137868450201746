import { Extras, Primitive } from '@sentry/core';
import * as Sentry from '@sentry/react';
import { Console, ConsoleGroupChild } from 'utils/console';

interface SentryOptions {
  tags?: Record<string, Primitive>;
  extra?: Extras;
}

interface SendErrorOptions {
  title?: string;
  children?: ConsoleGroupChild[];
  sentry?: SentryOptions;
  log?: boolean;
}

export const sendError = (error: string | Error, options?: SendErrorOptions) => {
  const { title, children, sentry, log = true } = options ?? {};

  const errorTitle = title || 'Error';
  const errorObject = typeof error === 'string' ? new Error(error) : error;

  if (log) {
    if (children && children.length) {
      const groupChildren: ConsoleGroupChild[] = [
        ...children,
        {
          title: 'Error',
          payload: error,
          type: 'error',
        },
      ];

      Console.printGroup(errorTitle, error, groupChildren, 'error');
    } else {
      Console.error(errorTitle, error);
    }
  }

  Sentry.captureException(errorObject, {
    level: 'error',
    tags: {
      errorTitle: title,
      ...sentry?.tags,
    },
    extra: sentry?.extra,
  });
};
